import React, { createContext } from 'react';

import { useCommonPageProps } from '../Hooks/useCommonPageProps';

export const CommonPageContext = createContext();

export const CommonPageProvider = ({ children, ...props }) => {
  const contextValue = useCommonPageProps(props); // Usa as props centralizadas

  return <CommonPageContext.Provider value={contextValue}>{children}</CommonPageContext.Provider>;
};
