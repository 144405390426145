import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import profile from '../../../services/profile';
import requests from '../../../services/requests';
import { filterOptionsTransportSchedule } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import FormEventScheduling from './components/FormEventScheduling';
import HeaderCustomCard from './components/HeaderCustomCard';
import TransportScheduleCircuit from './components/TransportScheduleCircuit';
import TransportScheduleCourtyard from './components/TransportScheduleCourtyard';
import TransportScheduleDay from './components/TransportScheduleDay';
import TransportScheduleKanban from './components/TransportScheduleKanban';
import TransportScheduleMonth from './components/TransportScheduleMonth';
import TransportScheduleWeek from './components/TransportScheduleWeek';

const TransportSchedulePage = () => {
  const hasPermission = permissions.warehouseStockAging;
  const isSystemAdmin = profile.role;

  const request = useRef(0);
  const calendarRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);

  const [selectedTab, setSelectedTab] = useState('Agenda');
  const [selectedAgendaTab, setSelectedAgendaTab] = useState('week');

  const [forceSetSearchPeriod, setForceSetSearchPeriod] = useState();
  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [events, setEvents] = useState([]);
  const [period, setPeriod] = useState({});

  const [eventsDetails, setEventsDetails] = useState([]);

  const load = async (filters, key) => {
    const removeKeys = (originalFilters, keysToRemove) => {
      const newFilters = { ...originalFilters };
      keysToRemove.forEach((key) => {
        if (newFilters[key] !== undefined) {
          delete newFilters[key];
        }
      });

      if (newFilters.filters) {
        keysToRemove.forEach((key) => {
          if (key === 'filters.stageModality' && newFilters.filters.stageModality !== undefined) {
            delete newFilters.filters.stageModality;
          }
        });
      }

      return newFilters;
    };

    const prepareFilters = (tab) => {
      switch (tab) {
        case 'Agenda':
          return { ...filters };
        case 'Kanban':
          return removeKeys(filters, ['period']);
        case 'Circuito':
          return removeKeys(filters, ['period']);
        case 'Pátio':
          return removeKeys(filters, ['period', 'filters.stageModality']);
        default:
          return { ...filters };
      }
    };

    const getTabRequest = (tab) => {
      const tabFilters = prepareFilters(tab);
      switch (tab) {
        case 'Agenda':
          return requests.getListTransportSchedule(tabFilters, selectedAgendaTab, 'all');
        case 'Kanban':
          return requests.getListTransportSchedule(tabFilters, 'period', 'all');
        case 'Circuito':
          return requests.getListTransportSchedule(tabFilters, 'period', 'all');
        case 'Pátio':
          return requests.getListTransportSchedule(tabFilters, 'period', 'no-patio');
        default:
          return requests.getListTransportSchedule(tabFilters, 'period', 'all');
      }
    };

    const res = await executeRequest({
      action: () => getTabRequest(selectedTab),
      setIsLoading,
    });

    if (request.current && request.current > key) {
      return;
    }

    request.current = key;

    setEvents(res?.data);

    if (selectedTab === 'Agenda') {
      setPeriod(res?.dates);
    }
  };

  const handlePrevious = () => {
    if (isLoading) return;
    setIsLoading(true);

    setForceSetSearchPeriod({
      startsAt: period.previous,
    });

    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
    }
  };

  const handleNext = () => {
    if (isLoading) return;
    setIsLoading(true);

    setForceSetSearchPeriod({
      startsAt: period.next,
    });

    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.next();
    }
  };

  const loadEventDetails = async (identifier) => {
    const res = await executeRequest({
      action: () => requests.getEventDetails(identifier),
    });
    setEventsDetails(res);

    onOpen();
  };

  const tabs = {
    Agenda: {
      day: <TransportScheduleDay calendarRef={calendarRef} events={events} loadEventDetails={loadEventDetails} />,
      week: <TransportScheduleWeek calendarRef={calendarRef} events={events} loadEventDetails={loadEventDetails} />,
      month: <TransportScheduleMonth calendarRef={calendarRef} events={events} loadEventDetails={loadEventDetails} />,
    },
    Kanban: <TransportScheduleKanban events={events} loadEventDetails={loadEventDetails} />,
    Circuito: <TransportScheduleCircuit events={events} loadEventDetails={loadEventDetails} />,
    Pátio: <TransportScheduleCourtyard events={events} loadEventDetails={loadEventDetails} />,
  };

  const loadFilterOptions = async () => {
    const filters = await filterOptionsTransportSchedule();
    setFilterOptions(filters);
  };

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  }, [selectedAgendaTab, selectedTab]);

  return (
    <Page
      screen="transport-schedule"
      title="Agenda de transporte"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Processo, empresa ou ref"
      hasPermission={hasPermission}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      load={load}
      filterOptions={filterOptions}
      forceSetSearchPeriod={forceSetSearchPeriod}
      forceLoadTrigger={forceLoadTrigger}
      showRefreshData={false}
      showPeriodFilter={false}
      allowEmptySearchPeriod>
      <Card
        h="calc(100vh - 192px)"
        m="10px"
        header={
          <HeaderCustomCard
            isLoading={isLoading}
            period={period}
            selectedTab={selectedTab}
            selectedAgendaTab={selectedAgendaTab}
            setSelectedAgendaTab={setSelectedAgendaTab}
            handleTabChange={({ label }) => setSelectedTab(label)}
            isSystemAdmin={isSystemAdmin}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            setForceSetSearchPeriod={setForceSetSearchPeriod}
          />
        }>
        <ScreenLoader isLoading={isLoading} overflowY="auto">
          {selectedTab === 'Agenda' ? (
            <Box h="full" p="10px" className="up-anim">
              {tabs.Agenda[selectedAgendaTab]}
            </Box>
          ) : (
            tabs[selectedTab]
          )}
        </ScreenLoader>
      </Card>

      <CommonDrawer heading="Agendamento" headingFlag={`#${eventsDetails.code}`} placement="left" isOpen={isOpen} onClose={onClose}>
        <FormEventScheduling events={eventsDetails} />
      </CommonDrawer>
    </Page>
  );
};

export default TransportSchedulePage;
