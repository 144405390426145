import { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import useScreenPage from '../../../hooks/useScreenPage';

export const useCommonPageProps = (props) => {
  const { children, ...rest } = props;

  //default properties
  const list = rest?.list;
  const screen = rest?.screen;
  /* const setIsMobile = rest?.setIsMobile;
  const setDeviceType = rest?.setDeviceType; */
  //info
  const title = rest?.title;
  const detailsTitle = rest?.detailsTitle;
  //form
  const nameForm = rest?.nameForm;
  //permissions
  const hasPermission = rest?.hasPermission ?? true;
  //breadcrumbs
  const breadcrumbIcon = rest?.breadcrumbIcon;
  const breadcrumbs = rest?.breadcrumbs;
  //pagination
  const Page = rest?.Page;
  const metadata = rest?.metadata;
  //loading
  const load = rest?.load;
  const loadMore = rest?.loadMore;
  const loadCharts = rest?.loadCharts;
  const isContentLoading = rest?.isContentLoading ?? false;
  const isContentLoadingMore = rest?.isContentLoadingMore ?? false;

  const disableMultiUpload = rest?.disableMultiUpload ?? false;
  const disableAdditionalFields = rest?.disableAdditionalFields ?? false;

  const allowFiltersOverride = rest?.allowFiltersOverride ?? false;
  const filterOptions = rest?.filterOptions;
  const textFilterPlaceholder = rest?.textFilterPlaceholder;
  const isRefreshLoading = rest?.isRefreshLoading;
  const showRefreshData = rest?.showRefreshData ?? true;

  const hideAllFilters = rest?.hideAllFilters ?? false;
  const showFilters = rest?.showFilters ?? true;
  const showPeriodFilter = rest?.showPeriodFilter ?? true;
  const showTextFilter = rest?.showTextFilter ?? true;
  const allowEmptySearchPeriod = rest?.allowEmptySearchPeriod ?? false;
  const allowEmptySearchFilterData = rest?.allowEmptySearchFilterData ?? false;
  const allowEmptySearchString = rest?.allowEmptySearchString ?? false;

  const sorting = rest?.sorting;
  const additionalFormFields = rest?.additionalFormFields ?? [];

  const isLoading = rest?.isLoading ?? false;

  const forceLoadTrigger = rest?.forceLoadTrigger ?? null;
  const forceSetSearchPeriod = rest?.forceSetSearchPeriod ?? null;

  const FAB = rest?.FAB ?? null;

  const useOwnState = rest?.useOwnState ?? false;

  const ownState = rest?.ownState;
  const setOwnState = rest?.setOwnState;

  const hasChartChanges = rest?.hasChartChanges;

  const refreshSeconds = rest?.refreshSeconds;

  const showScreenshot = rest?.showScreenshot ?? false;
  const refScreen = rest?.refScreen ?? null;
  const ScreenShotIsDisabled = rest?.ScreenShotIsDisabled ?? false;

  const navigate = useNavigate();

  //responsive
  const mobile = useResponsiveCheck();
  const { isMobile: isMob, setDeviceType: device, viewport: view } = mobile;

  const [isMobile, setIsMobile] = useState(isMob);
  const [deviceType, setDeviceType] = useState(device);
  const [viewport, setViewport] = useState(view);

  const pageRef = useRef(null);

  const currentPageName = useScreenPage();

  const [pageNumber, setPageNumber] = useState(() => {
    return metadata?.current_page && metadata?.current_page >= 1 ? metadata?.current_page : 1;
  });

  const [paginationHelper, setPaginationHelper] = useState({
    loadedPages: [],
    totalCount: null,
  });

  const [searchString, setSearchString] = useState('');
  const [searchPeriod, setSearchPeriod] = useState();
  const [searchFilterData, setSearchFilterData] = useState(null);
  const [shouldIgnoreFilters, setShouldIgnoreFilters] = useState(false);

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  const shouldPreventSearch = () => {
    let allow = false;

    if (
      sorting &&
      ((typeof sorting === 'object' && Object.keys(sorting).length > 0) || (typeof sorting === 'array' && sorting.length > 0))
    ) {
      allow = true;
    }

    if (allowEmptySearchPeriod || (searchPeriod && Object.keys(searchPeriod).length > 0)) {
      allow = true;
    }

    if (allowEmptySearchFilterData || (searchFilterData && Object.keys(searchFilterData).length > 0)) {
      allow = true;
    }

    if (allowEmptySearchString || (searchString && searchString.length > 0)) {
      allow = true;
    }

    return !allow;
  };

  const getFilters = () => ({
    search: searchString,
    searchOveridesFilters: shouldIgnoreFilters,
    period: searchPeriod,
    filters: searchFilterData,
    sort: sorting,
    currentPageName,
  });

  const getFiltersKey = () => moment(new Date()).format('x');

  const onChangePeriod = (date) => setSearchPeriod(date);

  const onChangeFilters = (value) => setSearchFilterData(value);

  const onChangeTextFilter = (value) => {
    let aux = value.trim();
    if (aux.length > 2 || aux.length === 0) {
      setSearchString(aux);
    }
  };

  const onChangeshouldIgnoreFilters = (value) => setShouldIgnoreFilters(value);

  const refresh = () => {
    if (shouldPreventSearch()) {
      return;
    }

    loadCharts && typeof loadCharts === 'function' && loadCharts(getFilters(), getFiltersKey());
    load && typeof load === 'function' && load(getFilters(), getFiltersKey(), pageNumber);
  };

  useEffect(() => {
    if (!hasPermission) {
      navigate('/');

      return;
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      return;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pageRef, list]);

  useEffect(() => {
    if (shouldPreventSearch()) {
      return;
    }

    if (typeof loadCharts === 'function') {
      loadCharts(getFilters(), getFiltersKey());
    }

    if (typeof load === 'function') {
      load(getFilters(), getFiltersKey(), pageNumber);
    }
  }, [searchString, searchPeriod, searchFilterData, shouldIgnoreFilters, forceLoadTrigger, pageNumber, hasChartChanges]);

  useEffect(() => {
    if (shouldPreventSearch()) {
      return;
    }

    if (typeof loadMore === 'function') {
      // Não pagina se houver loadMore definido
      return;
    }

    if (typeof load === 'function') {
      // Não deve disparar o load se o sorting não tiver valor definido e a páginação for a primeira
      if ((sorting === undefined || sorting === null) && pageNumber === 1) {
        return;
      }

      load(getFilters(), getFiltersKey(), pageNumber);
    }
  }, [sorting]);

  useEffect(() => {
    if (metadata?.total_count !== paginationHelper.totalCount) {
      setPaginationHelper({
        loadedPages: [metadata?.current_page],
        totalCount: metadata?.total_count,
      });
    } else if (!paginationHelper.loadedPages.includes(metadata?.current_page)) {
      setPaginationHelper({
        loadedPages: [...paginationHelper.loadedPages, metadata?.current_page],
        totalCount: metadata?.total_count,
      });
    }

    if (typeof loadMore === 'function') {
      // Não pagina se houver loadMore definido
      return;
    }

    let newPage = metadata && metadata?.current_page !== 0 ? metadata?.current_page : 1;
    if (newPage !== pageNumber) {
      setPageNumber(newPage);
    }
  }, [metadata]);

  useEffect(() => {
    if (forceSetSearchPeriod) {
      setSearchPeriod(forceSetSearchPeriod);
    }
  }, [forceSetSearchPeriod]);

  useEffect(() => {
    if (isContentLoadingMore) {
      loadMore(getFilters());
    }
  }, [isContentLoadingMore]);

  return {
    //default properties
    screen,
    isMobile,
    setIsMobile,
    deviceType,
    setDeviceType,
    viewport,
    setViewport,
    title,
    detailsTitle,
    nameForm,
    breadcrumbIcon,
    breadcrumbs,
    hasPermission,
    Page,
    metadata,
    load,
    isContentLoading,
    disableMultiUpload,
    disableAdditionalFields,
    loadMore,
    isContentLoadingMore,
    loadCharts,
    allowFiltersOverride,
    filterOptions,
    textFilterPlaceholder,
    isRefreshLoading,
    showRefreshData,
    hideAllFilters,
    showFilters,
    showPeriodFilter,
    showTextFilter,
    allowEmptySearchPeriod,
    allowEmptySearchFilterData,
    allowEmptySearchString,
    sorting,
    additionalFormFields,
    isLoading, // Loading para tela inteira
    forceLoadTrigger,
    forceSetSearchPeriod,
    FAB,
    useOwnState,
    ownState,
    setOwnState,
    hasChartChanges,
    refreshSeconds,
    showScreenshot,
    refScreen,
    ScreenShotIsDisabled,
    shouldIgnoreFilters,
    pageRef,
    pageNumber,
    currentPageName,
    isOpenFloatButton,
    setIsOpenFloatButton,
    shouldPreventSearch,
    getFilters,
    getFiltersKey,
    onChangePeriod,
    onChangeFilters,
    onChangeTextFilter,
    onChangeshouldIgnoreFilters,
    refresh,
  };
};
