import React from 'react';

import moment from 'moment';
import { MdOpenInNew } from 'react-icons/md';

import { Box, Flex } from '@chakra-ui/layout';
import { IconButton, Image, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import vehicle from '../../../../assets/images/vehicle-courtyard.svg';

const TransportScheduleCourtyard = ({ events, loadEventDetails }) => {
  const happenedAt = (event) => {
    const stagePriority = ['Agendado'];

    const priorityStage = stagePriority
      .map((stageName) => event.stage?.find((stage) => stage.stage === stageName))
      .find((stage) => stage);

    const start = priorityStage ? priorityStage.happenedAt.replace(' ', 'T') : event.expectedAt?.replace(' ', 'T') || null;

    return moment(start).format('HH:mm') === '00:00' ? moment(start).format('DD/MM/YYYY') : moment(start).format('DD/MM/YYYY HH:mm');
  };

  const header = ['Veículo', 'Entrada', 'Data', 'Responsável', 'Armazém', 'Doca', ''];

  return (
    <Flex h="600px" w="full" direction="column" p="25px">
      <Table w="full">
        <Thead>
          <Tr className="border">
            <Th>
              <Flex p="10px !important"></Flex>
            </Th>

            {header.map((header, index) => (
              <Th key={index}>
                <Flex p="10px !important">{header}</Flex>
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {events.length > 0 ? (
            events.map((entity, index) => (
              <Tr className="border" key={index}>
                <Td>
                  <Flex p="10px !important" justify="center">
                    <Image src={vehicle} />
                  </Flex>
                </Td>

                <Td maxW="200px">
                  <Flex p="10px !important">{entity.vehiclePlates ?? '-'}</Flex>
                </Td>

                <Td maxW="200px">
                  <Flex p="10px !important">{entity?.modality ?? '-'}</Flex>
                </Td>

                <Td maxW="200px">
                  <Flex p="10px !important">{happenedAt(entity)}</Flex>
                </Td>

                <Td>
                  <Flex p="10px !important">{entity?.companyCarrier?.name ?? '-'}</Flex>
                </Td>

                <Td>
                  <Flex p="10px !important">{entity?.companyWarehouse?.name ?? '-'}</Flex>
                </Td>

                <Td maxW="200px">
                  <Flex p="10px !important">{entity?.dockNumber ?? '-'}</Flex>
                </Td>

                <Td maxW="200px">
                  <Tooltip label="Detalhe da agenda">
                    <Box>
                      <IconButton
                        bg="transparent"
                        icon={<MdOpenInNew size={20} color="#6C48C2" />}
                        onClick={() => {
                          loadEventDetails(entity.identifier);
                        }}
                      />
                    </Box>
                  </Tooltip>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className="border">
              <Td colSpan={8}>
                <Flex p="10px !important" fontWeight="medium" color="#4A5568 !important" justify="center">
                  Não há resultados que correspondam aos filtros selecionados.
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Flex>
  );
};

export default TransportScheduleCourtyard;
