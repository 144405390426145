import React from 'react';

import '../styles/transportScheduleDay.css';

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { MdTurnedIn } from 'react-icons/md';

import { Box, Flex, Text } from '@chakra-ui/layout';
import { Image, Tooltip } from '@chakra-ui/react';

import { formatEvents } from '../utils/formatEvents';
import { getColorByText } from '../utils/getColorByText';
import { getStatusColor } from '../utils/getStatusColor';

import { getIconByText } from './getIconByText';

const TransportScheduleDay = ({ calendarRef, events, loadEventDetails }) => {
  const TransportEvent = ({ eventInfo }) => {
    return (
      <Flex
        h="full"
        w="fit-content"
        bgColor="#F5F5F5"
        align="center"
        gap="10px"
        borderRadius="0px 6px 6px 0px"
        cursor="pointer"
        _hover={{ bgColor: '#F2E9FF' }}
        onClick={() => loadEventDetails(eventInfo.identifier)}>
        <Box h="full" minW="7px" borderRadius="6px 0px 0px 6px" bgColor={getColorByText(eventInfo.modality)} />

        <Flex h="full" w="full" align="center" justify="space-between" gap="10px">
          <Flex direction="column">
            <Tooltip label={eventInfo?.companyName}>
              <Text maxW="130px" fontSize="14px" fontWeight="medium" textColor="primary" isTruncated>
                {eventInfo?.companyName}
              </Text>
            </Tooltip>

            <Tooltip label={`Doca ${eventInfo.dockNumber}`}>
              <Text w="fit-content" fontSize="12px" textColor="#A6A6A6">
                Doca {eventInfo.dockNumber}
              </Text>
            </Tooltip>
          </Flex>

          <Flex h="full" w="full" justify="flex-end" gap="10px">
            <Tooltip label={eventInfo.modality}>
              <Image w="25px" src={getIconByText(eventInfo.modality)} />
            </Tooltip>

            <Tooltip label={eventInfo.stageModalityFormatted}>
              <Box h="fit-content">
                <MdTurnedIn size="20px" color={getStatusColor(eventInfo.stageModality)} />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <FullCalendar
      ref={calendarRef}
      height="100%"
      initialView="timeGridDay"
      plugins={[dayGridPlugin, timeGridPlugin]}
      locale={ptBrLocale}
      events={formatEvents(events)}
      allDaySlot={false}
      nowIndicator
      eventContent={(eventInfo) => <TransportEvent eventInfo={eventInfo.event.extendedProps} />}
      dayHeaderFormat={{
        weekday: 'long',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }}
      headerToolbar={{
        start: '',
        center: '',
        end: '',
      }}
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
      }}
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
      }}
    />
  );
};

export default TransportScheduleDay;
