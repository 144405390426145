import React from 'react';

import '../styles/transportScheduleWeek.css';

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { MdTurnedIn } from 'react-icons/md';

import { Box, Flex, Grid, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';

import { formatEvents } from '../utils/formatEvents';
import { getColorByText } from '../utils/getColorByText';
import { getStatusColor } from '../utils/getStatusColor';

const TransportScheduleWeek = ({ calendarRef, events, loadEventDetails }) => {
  const TransportEvent = ({ eventInfo }) => {
    return (
      <Flex
        h="full"
        w="full"
        bgColor="#F5F5F5"
        align="center"
        gap="3px"
        borderRadius="0px 6px 6px 0px"
        cursor="pointer"
        position="relative"
        zIndex={1}
        _hover={{ bgColor: '#F2E9FF' }}
        onClick={() => loadEventDetails(eventInfo.identifier)}>
        <Box h="full" minW="7px" bgColor={getColorByText(eventInfo.modality)} borderRadius="6px 0px 0px 6px" />

        <Flex h="full" w="full" justify="space-between" gap="3px" overflow={'hidden'}>
          <Grid direction="column" overflow={'hidden'} alignItems="center" justifyItems={'start'}>
            <Tooltip label={eventInfo?.companyName}>
              <Text fontSize="10px" fontWeight="medium" textColor="primary" overflow={'hidden'} isTruncated>
                {eventInfo?.companyName}
              </Text>
            </Tooltip>

            <Tooltip label={`Doca ${eventInfo.dockNumber}`}>
              <Text fontSize="9px" textColor="#A6A6A6" overflow={'hidden'} isTruncated>
                Doca {eventInfo.dockNumber}
              </Text>
            </Tooltip>
          </Grid>

          <Flex justify="flex-end">
            <Tooltip label={eventInfo.stageModalityFormatted}>
              <Box>
                <MdTurnedIn size="20px" color={getStatusColor(eventInfo.stageModality)} />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <style>
        {`
          .fc-timegrid-event-harness.fc-timegrid-event-harness-inset:hover {
            z-index: 9999 !important;
            height: 50px !important;
            width: 100%;
         }
      `}
      </style>

      <FullCalendar
        ref={calendarRef}
        height="100%"
        initialView="timeGridWeek"
        plugins={[dayGridPlugin, timeGridPlugin]}
        locale={ptBrLocale}
        events={formatEvents(events)}
        allDaySlot={false}
        nowIndicator
        eventContent={(eventInfo) => <TransportEvent eventInfo={eventInfo.event.extendedProps} />}
        loadEventDetails={loadEventDetails}
        dayHeaderFormat={{
          weekday: 'long',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }}
        headerToolbar={{
          start: '',
          center: '',
          end: '',
        }}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
        }}
      />
    </>
  );
};

export default TransportScheduleWeek;
