import React from 'react';

import moment from 'moment';

import { Box, Flex, FormControl, FormLabel, Grid, Image, Text } from '@chakra-ui/react';

import 'moment/locale/pt-br';
import { getStatusColor } from '../utils/getStatusColor';

import { getIconByText } from './getIconByText';

moment.locale('pt-br');

const FormEventScheduling = ({ events }) => {
  const generalInfo = [
    { label: 'Tipo de agendamento', value: events.modality },
    { label: 'Número do container', value: events.container },
    { label: 'Número da DI', value: events.diNumber },
    { label: 'CNPJ', value: events.companyWarehouse?.documentNumber },
    { label: 'Placa do veículo', value: events.vehiclePlates },
    { label: 'Documento do motorista', value: events.driverDocument },
    { label: 'Nome do motorista', value: events.driverName },
  ];

  const companyInfo = [
    { label: 'Armazém da Empresa', value: events.companyWarehouse?.name },
    { label: 'CNPJ do Armazém', value: events.companyWarehouse?.documentNumber },
    { label: 'Depositante', value: events.companyDepositor?.name },
    { label: 'CNPJ do Depositante', value: events.companyDepositor?.documentNumber },
  ];

  const stageHistory = events.stage.map((stage) => ({
    label: stage.stage,
    value: moment(stage.happenedAt).format('DD/MM/Y HH:mm'),
  }));

  const getFormattedEventTime = (event) => {
    const lastStage = event.stage?.length ? event.stage[event.stage.length - 1] : null;

    const eventTime = lastStage ? lastStage.happenedAt : event.expectedAt;

    return moment(eventTime).format('HH:mm') === '00:00'
      ? moment(eventTime).format('DD/MM/YYYY')
      : moment(eventTime).format('DD/MM/YYYY HH:mm');
  };

  const happenedAt = (event) => {
    const stagePriority = ['Agendado'];

    const priorityStage = stagePriority
      .map((stageName) => event.stage?.find((stage) => stage.stage === stageName))
      .find((stage) => stage);

    const start = priorityStage ? priorityStage.happenedAt.replace(' ', 'T') : event.expectedAt?.replace(' ', 'T') || null;

    return moment(start).format('HH:mm') === '00:00' ? moment(start).format('DD/MM/YYYY') : moment(start).format('DD/MM/YYYY HH:mm');
  };

  const renderGridSection = (title, data, columns, stage) => (
    <>
      <Text fontWeight="bold" mt="10px">
        {title}
      </Text>

      <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} bgColor="#F5F5F5" p="20px" borderRadius="6px" gap={stage ? '8px' : '20px'}>
        {data.map((field, index) => (
          <FormControl key={index}>
            <FormLabel fontWeight="bold">{field.label}</FormLabel>

            <Text textColor="#6B7280" fontSize={stage && '14px'}>
              {field.value || '-'}
            </Text>
          </FormControl>
        ))}
      </Grid>
    </>
  );

  return (
    <Flex direction="column" gap="10px" textColor="primary">
      <Grid gridTemplateColumns="repeat(3, 1fr)" bgColor="#F5F5F5" p="20px" borderRadius="6px">
        <Flex direction="column" justify="center">
          <Text fontWeight="bold">{happenedAt(events)}</Text>

          <Text textColor="#6B7280">
            {moment(events.expectedAt).format('dddd').charAt(0).toUpperCase() + moment(events.expectedAt).format('dddd').slice(1)}
          </Text>
        </Flex>

        <Flex align="center" gap="15px">
          <Image w="25px" src={getIconByText(events.modality)} />

          <Text textColor={events.modality === 'RECEBIMENTO' ? '#6c48c2' : '#a885f9'} fontWeight="bold">
            {events.modality}
          </Text>
        </Flex>

        <Flex h="full" align="center" gap="10px" color={getStatusColor(events.currentStageModality.slug)}>
          <Box h="full" w="4px" bgColor={getStatusColor(events.currentStageModality.slug)} />

          <Flex direction="column">
            <Text>Status</Text>

            <Text fontWeight="bold">{events.currentStageModality.name}</Text>

            <Text textColor="#6B7280">{getFormattedEventTime(events)}</Text>
          </Flex>
        </Flex>
      </Grid>

      {renderGridSection('Informações gerais', generalInfo, 2)}
      {renderGridSection('Empresas relacionadas', companyInfo, 2)}
      {events.stage?.length > 0 && renderGridSection('Histórico de estágios', stageHistory, 6, true)}
    </Flex>
  );
};

export default FormEventScheduling;
