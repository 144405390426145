import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { Button, Flex, Input, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

import ModalDetailAdditionalData from './ModalDetailsAdditionalData';

const AdditionalDataList = ({ process, onLoadData, updates, onProcessUpdates, getProcess }) => {
  let profileRole = localStorage.getItem('profile-role');

  const [changesToSave, setChangesToSave] = useState([]);
  const [data, setData] = useState([]);
  const [isLoadingSaveField, setIsLoadingSaveField] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getData = () => {
    if (!process || !process.identifier) {
      return;
    }

    requests
      .getAdditionalFieldLabels(process.identifier)
      .then((data) => {
        setData(data.fields);
        setIsLoadingData(false);
        onLoadData(data.fields);
      })
      .catch(() => {
        setIsLoadingData(false);
      });
  };

  useEffect(() => {
    getData();
  }, [process, updates]);

  return (
    <ScreenLoader isLoading={isLoadingData}>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th>Título do campo</Th>
            <Th>Valor do campo</Th>
            {profileRole !== 'ROLE_CLIENT' && <Th>Ações</Th>}
          </Tr>
        </Thead>
        <Tbody border="1px">
          {data?.length > 0 &&
            data.map(
              (entity, key) =>
                (entity?.value?.length > 0 || profileRole !== 'ROLE_CLIENT') && (
                  <Tr key={key} border="none" cursor="pointer" style={{ '--delay': `${key / 10}s` }}>
                    <Td>{entity?.label ?? '-'}</Td>
                    <Td>
                      {profileRole !== 'ROLE_CLIENT' ? (
                        <Input
                          variant="primary"
                          type="text"
                          defaultValue={entity.value}
                          name="name"
                          id="name"
                          autoComplete="off"
                          onChange={(el) => {
                            var changes = changesToSave;
                            changes[entity.identifier] = el.target.value;
                            setChangesToSave(changes);
                          }}
                        />
                      ) : (
                        entity.value
                      )}
                    </Td>
                    {profileRole !== 'ROLE_CLIENT' && (
                      <>
                        <Td>
                          <Flex gap="35px" align="center">
                            <Button
                              variant="secundary"
                              isLoading={isLoadingSaveField && isLoadingSaveField[entity.identifier]}
                              onClick={() => {
                                let aux = {};
                                aux[entity.identifier] = true;
                                setIsLoadingSaveField(aux);

                                requests
                                  .post('process/additional-field-value/edit', {
                                    process: process.identifier,
                                    field: entity.identifier,
                                    description:
                                      changesToSave[entity.identifier] !== undefined &&
                                      changesToSave[entity.identifier] !== entity.value
                                        ? changesToSave[entity.identifier]
                                        : entity.value || ' ',
                                  })
                                  .then((res) => {
                                    setIsLoadingSaveField(false);
                                    toast.success('Valor alterado com sucesso!');
                                    setIsLoadingSaveField({});
                                    onProcessUpdates();
                                    getProcess();
                                  })
                                  .catch((res) => {
                                    setIsLoadingSaveField({});
                                  });
                              }}>
                              <Text fontSize="14px">Salvar</Text>
                            </Button>
                            <ModalDetailAdditionalData entity={entity} />
                          </Flex>
                        </Td>
                      </>
                    )}
                  </Tr>
                )
            )}

          {isLoadingData ? (
            <Tr>
              <Td colSpan={3}>
                <Flex justify="center" my="25px">
                  Carregando...
                </Flex>
              </Td>
            </Tr>
          ) : (
            <>
              {data.length === 0 && (
                <Tr>
                  <Td colSpan={3}>
                    <Flex justify="center" my="25px">
                      Nenhum dado encontrado para os parâmetros filtrados.
                    </Flex>
                  </Td>
                </Tr>
              )}
            </>
          )}
        </Tbody>
      </Table>
    </ScreenLoader>
  );
};

export default AdditionalDataList;
