import React from 'react';

import { NumericFormat } from 'react-number-format';

const InputCurrency = ({ name, id, value, setFieldValue, placeholder, isDisabled, isFormattedValue = true }) => {
  return (
    <NumericFormat
      className="custom-input-mask"
      displayType="input"
      prefix="R$ "
      thousandSeparator="."
      decimalSeparator=","
      thousandsGroupStyle="thousand"
      id={id}
      name={name}
      value={value}
      disabled={isDisabled}
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={false}
      onValueChange={(values) => {
        const { formattedValue, value } = values;
        const newValue = isFormattedValue ? formattedValue : value;
        setFieldValue(name, newValue);
      }}
      placeholder={placeholder}
    />
  );
};

export default InputCurrency;
