import React, { useEffect, useRef, useState } from 'react';

import { FaDownload } from 'react-icons/fa';
import { MdAdd, MdOutlineFileDownload, MdOutlineOpenInNew } from 'react-icons/md';

import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';

import icon from '../../../../assets/images/expedition_documents.svg';
import CommonList from '../../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../../components/CommonModal/CommonModal';
import Page from '../../../../components/Page';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../../services/permissions';
import requests from '../../../../services/requests';
import { generateExportFile } from '../../../../utils/actions/generateExportFile';
import closeFloatActionButton from '../../../../utils/actions/modal/closeFloatActionButton';
import { handleDocuments } from '../../../../utils/documents/handleDocuments';
import { handleDownloadDocument } from '../../../../utils/documents/handleDownloadDocument';
import { filterOptionsWarehouseExpeditionDocuments } from '../../../../utils/filters/filterPresets';

import { commonListConfig } from './components/commonListConfig';
import ExpeditionUploadDocument from './ExpeditionUploadDocument';

const ExpeditionDocumentsPage = () => {
  let profileRole = localStorage.getItem('profile-role');

  const hasPermission = permissions.warehouseExpeditionDocumentsView || permissions.warehouseExpeditionDocumentsManagement;

  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  //triggers modal
  const { isOpen: isOpenView, onOpen: onOpenView, onClose: onCloseView } = useDisclosure();
  const { isOpen: isOpenNewDoc, onOpen: onOpenNewDoc, onClose: onCloseNewDoc } = useDisclosure(); // New state for the new document modal

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);
  const [documentPreview, setDocumentPreview] = useState();

  const [isLoadingModal, setIsLoadingModal] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  //component preset
  const subtitle = `Existe um total de ${metadata.total_count} documentos`;

  const load = (filters, key) => {
    setIsLoading(true);

    requests
      .listExepditionDocuments(filters, metadata.current_page > 0 ? metadata.current_page : 1)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;
        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const getClientOptions = () => {
    requests
      .listClients({}, '1', '99999', 'all')
      .then((response) => {
        let options = [];
        response?.data?.length > 0 &&
          response.data.map((row) => {
            if (row.documentNumber.length === 8) {
              return;
            }

            options.push({
              label: `${row.name} - (${row.documentNumberFormatted})`,
              value: row.identifier,
            });
          });

        setClientOptions(options);
      })
      .finally(() => {});
  };

  const exportDocumentsFABActionRomaneio = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const type = `cargo-detail`;
    const extension = `zip`;
    const endpoint = `/open/expedition/document/${type}/download`;
    const fileName = `Arquivos-romaneio`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportDocumentsFABActionCanhoto = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const type = `receipt-comprovant`;
    const extension = `zip`;
    const endpoint = `/open/expedition/document/${type}/download`;
    const fileName = `Arquivos-canhoto`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportDocumentsFABActionRomaneioCanhoto = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const type = `all`;
    const extension = `zip`;
    const endpoint = `/open/expedition/document/${type}/download`;
    const fileName = `Arquivos-romaneio-e-canhoto`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehouseExpeditionDocuments());
  };

  useEffect(() => {
    getClientOptions();
    getFilterOptions();
  }, []);

  const handleCustomButtonAction = (identifier, props) => {
    onOpenView();
    setIsLoadingModal(true);
    setDocumentPreview(handleDocuments(`/open/expedition/document/${identifier}/view?${new Date().getTime()}`, props?.uploadName));
    setIsLoadingModal(false);
  };

  const handleDownloadFile = (identifier, props) => {
    const endpoint = '/open/expedition/document/${identifier}/view';
    const uploadName = props?.name;

    handleDownloadDocument(endpoint, identifier, uploadName);
  };

  const handleDownloadAllDocuments = (identifier, props) => {
    const endpoint = '/open/expedition/document/${identifier}/download-all';
    const uploadName = props?.name;

    handleDownloadDocument(endpoint, identifier, uploadName);
  };

  const customButtons = [
    {
      main: [{ label: 'Baixar todos', tooltip: 'Baixar todos', icon: <FaDownload size={18} />, action: handleDownloadAllDocuments }],
      collapsed: [
        {
          label: 'Visualizar arquivo',
          tooltip: 'Visualizar arquivo',
          icon: <MdOutlineOpenInNew size={20} />,
          action: handleCustomButtonAction,
        },
        { label: 'Baixar arquivo', tooltip: 'Baixar arquivo', icon: <MdOutlineFileDownload size={20} />, action: handleDownloadFile },
      ],
    },
  ];

  return (
    <Page
      screen="contract-management"
      title="Documentos de expedição"
      breadcrumbIcon={icon}
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Nota fiscal ou número de carga"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      allowEmptySearchPeriod={true}
      filterOptions={filterOptions}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      FAB={
        permissions.warehouseExpeditionDocumentsManagement
          ? [
              {
                title: 'Exportar documentos de Romaneio',
                text: 'Exportar documentos de Romaneio anexos à Expedição',
                action: exportDocumentsFABActionRomaneio,
                modality: 'export-files',
              },
              {
                title: 'Exportar documentos de Canhoto',
                text: 'Exportar documentos de Canhoto anexos à Expedição',
                action: exportDocumentsFABActionCanhoto,
                modality: 'export-files',
              },
              {
                title: 'Exportar documentos de Romaneio e Canhoto',
                text: 'Exportar documentos de Romaneio e Canhoto anexos à Expedição',
                action: exportDocumentsFABActionRomaneioCanhoto,
                modality: 'export-files',
              },
              {
                title: 'Importar documentos de expedição',
                text: 'Upload de documentos',
                action: (
                  <Button
                    leftIcon={<MdAdd size={20} color="#ffffff" />}
                    _hover={{ bgColor: 'purple-400' }}
                    bg="primary"
                    p="1.5rem"
                    pb="1.5rem"
                    pl="1.3rem"
                    pr="1.3rem"
                    onClick={() => {
                      closeFloatActionButton();
                      onOpenNewDoc();
                    }}
                    borderRadius="50px">
                    <Text mr="10px" color="white">
                      Novo documento
                    </Text>
                  </Button>
                ),
                modality: 'custom',
              },
            ]
          : [
              {
                title: 'Exportar documentos de Romaneio',
                text: 'Exportar documentos de Romaneio anexos à Expedição',
                action: exportDocumentsFABActionRomaneio,
                modality: 'export-files',
              },
              {
                title: 'Exportar documentos de Canhoto',
                text: 'Exportar documentos de Canhoto anexos à Expedição',
                action: exportDocumentsFABActionCanhoto,
                modality: 'export-files',
              },
              {
                title: 'Exportar documentos de Romaneio e Canhoto',
                text: 'Exportar documentos de Romaneio e Canhoto anexos à Expedição',
                action: exportDocumentsFABActionRomaneioCanhoto,
                modality: 'export-files',
              },
            ]
      }>
      <Box m={'0 10px'}>
        <CommonList
          subtitle={subtitle}
          //lists
          list={list}
          rawData={list}
          //actions
          action={action}
          sorting={sorting}
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
          handleSort={handleSort}
          //custom
          customButtons={customButtons}
          //loading
          isLoading={isLoading}
          //props
          {...commonListConfig}
        />
      </Box>

      <CommonModal isOpen={isOpenView} onOpen={onOpenView} onClose={onCloseView} closeButton={true} fullpage={true}>
        <ScreenLoader isLoading={isLoadingModal}>{documentPreview}</ScreenLoader>
      </CommonModal>

      <CommonModal
        isOpen={isOpenNewDoc}
        onOpen={onOpenNewDoc}
        onClose={onCloseNewDoc}
        closeButton={true}
        size={'3xl'}
        removeRadius={true}>
        <ExpeditionUploadDocument clientOptions={clientOptions} onClose={onCloseNewDoc} load={load} />
      </CommonModal>
    </Page>
  );
};

export default ExpeditionDocumentsPage;
