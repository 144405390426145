import { useContext } from 'react';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { IconButton } from '@chakra-ui/button';
import { Flex, Text } from '@chakra-ui/layout';

import ScreenLoader from '../../../ScreenLoader/ScreenLoader';
import { CommonListContext } from '../../contexts/CommonListContext';

export const PeriodBar = ({ children, ...props }) => {
  // Utiliza o contexto para acessar os filtros personalizados
  const { isLoading } = useContext(CommonListContext);
  const { handlePrevious, handleNext, formmated } = props;

  return (
    <Flex h="54px" w="full" align="center" justify="space-between" className="up-anim">
      <IconButton
        h="full"
        bgColor="#6C48C2"
        borderRadius="0px"
        _hover={{ bgColor: '#6C48C2', opacity: 0.8 }}
        onClick={handlePrevious}
        isLoading={isLoading}
        isDisabled={isLoading}
        icon={<MdKeyboardArrowLeft color="white" size={30} />}
      />

      <ScreenLoader isLoading={isLoading}>
        <Flex h="full" w="full" align="center" justify="center" border="2px solid #6C48C2">
          <Text fontSize="20px" textColor="#6C48C2" fontWeight="bold">
            {formmated}
          </Text>
        </Flex>
      </ScreenLoader>

      <IconButton
        h="full"
        bgColor="#6C48C2"
        borderRadius="0px"
        _hover={{ bgColor: '#6C48C2', opacity: 0.8 }}
        onClick={handleNext}
        isLoading={isLoading}
        isDisabled={isLoading}
        icon={<MdKeyboardArrowRight color="white" size={30} />}
      />
    </Flex>
  );
};
