import React, { useEffect, useRef, useState } from 'react';

import { Formik } from 'formik';
import moment from 'moment/moment';
import { MdOutlineInsertDriveFile } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Accordion, Box, Button, Flex, Grid, Text, useDisclosure } from '@chakra-ui/react';

import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';
import freightPaymentValidation from '../../Helpers/freightPaymentValidation';
import generalInformationValidation from '../../Helpers/generalInformationValidation';
import { initialValues } from '../../Helpers/initialValues';
import mainDataValidation from '../../Helpers/mainDataValidation';
import partTripValidation from '../../Helpers/partTripValidation';
import { validationSchema } from '../../Helpers/validationSchema';

import CardFreight from './CardFreight';
import CardGeneralInformation from './CardGeneralInformation';
import CardMainData from './CardMainData';
import CardTrip from './CardTrip';
import ModalFreightConfirm from './ModalFreightConfirm';
import StepRegister from './StepRegister';

const RegisterFreight = ({
  title,
  subTitle,
  entity,
  onCancelled,
  tab,
  geolocationList,
  setGeolocationList,
  reloadKanban,
  reloadList = { reloadList },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  // const [step, setStep] = useState(3);

  const [newStatus, setNewStatus] = useState('');
  const [docsTripSelected, setDocsTripSelected] = useState(entity?.tripFiles ?? []);
  const [docsTrajectoriesSelected, setDocsTrajectoriesSelected] = useState(entity?.cteFiles ?? []);
  const [filterOptions, setFilterOptions] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clientList, setClientList] = useState(entity?.tripClients ?? []);
  const [processList, setProcessList] = useState(entity?.processesCode ?? []);

  const initialIconErrors = entity?.identifier == null ? true : false;
  const [cardMainDataError, setCardMainDataError] = useState(initialIconErrors);
  const [cardTripError, setCardTripError] = useState(initialIconErrors);
  const [cardFreightError, setCardFreightError] = useState(initialIconErrors);
  const [cardGeneralInformationError, setCardGeneralInformationError] = useState(initialIconErrors);
  const [vehicles, setVehicles] = useState([]);

  const [showAlert, setShowAlert] = useState(true);

  const containerRef = useRef(null);
  const [height, setHeight] = useState(0);

  const listVehicles = () => {
    if (vehicles?.length == 0) {
      requests.listVehicles({}, 0, 99999).then((list) => {
        let options = [];
        list.data.map((vehicle) => {
          return options.push({
            value: vehicle.identifier,
            label: vehicle.licensePlate,
          });
        });
        setVehicles(options);
      });
    }
  };

  // const getFilterOptions = async () => {
  //   setFilterOptions(await filterOptionsAddTravel());
  // };

  const getClients = async () => {
    requests.listClients(null, '1', '99999').then((data) => {
      const aux = data?.data?.map((item) => {
        if (item.type?.toLowerCase() !== 'filial') {
          return {
            ...item,
            label: `${item.name} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
            value: item.identifier,
            qttChildren: item.qttChildren,
            documentNumber: item.documentNumber,
          };
        }
    
        return {
          ...item,
          label: ` ${'\u00A0'} - ${item.name} (${item.documentNumberFormatted})`,
          value: item.identifier,
          qttChildren: item.qttChildren,
          documentNumber: item.documentNumber,
        };
      });

      setFilterOptions({
        isMultiple: true,
        options: aux,
      });
    });
  };

  const validateSchema = async (schema, values, setFieldError, index) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return true;
    } catch (errors) {
      errors.inner.forEach((error) => {
        let path = error.path;
        if (index != -1) {
          path = `trip[${index}].${path}`;
        }

        setFieldError(path, error.message);
      });
      return false;
    }
  };

  const handleValidation = async (schema, values, setFieldError, setCardError, index = -1) => {
    const result = await validateSchema(schema, values, setFieldError, index);
    if (!result) {
      setCardError(true);
    } else {
      setCardError(false);
    }

    return result;
  };

  const handleMainValidation = async (values, setFieldError) => {
    let errors = 0;

    if (clientList.length === 0) {
      errors++;
      setFieldError('client', 'Campo obrigatório');
      setCardMainDataError(true);
    }

    // if (docsTripSelected?.length === 0) {
    //   errors++;
    //   setFieldError('driverFreightFiles', 'Campo obrigatório');
    //   setCardMainDataError(true);
    // }

    if (
      values?.main?.modality?.value === 'FRETE_RASTREAVEL' &&
      typeof values?.main?.typeExternalCode === 'object' &&
      values?.main?.typeExternalCode?.value !== 'OPENTECH'
    ) {
      errors++;
      setFieldError('typeExternalCode', 'Tipo deve ser código Opentech');
      setCardMainDataError(true);
    }

    const result = await handleValidation(mainDataValidation(values.main), values.main, setFieldError, setCardMainDataError);
    return errors === 0 ? result : false;
  };

  const handleTripValidation = async (values, setFieldError) => {
    const tripValidation = partTripValidation();
    let collectionPoints = 0;
    let deliveryPoints = 0;

    const tripResults = await Promise.all(
      (values.trip || []).map(async (item, key) => {
        if (item?.type?.value == 'COLETA') {
          collectionPoints++;
        } else if (item?.type?.value == 'ENTREGA') {
          deliveryPoints++;
        }

        return await handleValidation(tripValidation, item, setFieldError, setCardTripError, key);
      })
    );

    if (tripResults.some((result) => !result)) {
      const error = tripResults?.filter((result) => !result)?.length;
      setCardTripError(true);
      return error;
    } else if (collectionPoints == 0 || deliveryPoints == 0) {
      const lastIndex = values?.trip?.length - 1;
      setFieldError(`trip[${lastIndex}].type`, 'Obrigatório ter ao menos um trecho de coleta e entrega');
      setCardTripError(true);
      return true;
    } else {
      setCardTripError(false);
      return 0;
    }
  };

  const handleFreightValidation = async (values, setFieldError) => {
    return await handleValidation(freightPaymentValidation(), values.freight, setFieldError, setCardFreightError);
  };

  const handleInformationValidation = async (values, setFieldError) => {
    return await handleValidation(generalInformationValidation(), values.information, setFieldError, setCardGeneralInformationError);
  };

  const validatedForm = async (values, setFieldError, schema = 'fullSchema', errors = {}) => {
    let countError = 0;
    switch (schema) {
      case 'mainSchema':
        countError += (await handleMainValidation(values, setFieldError)) ? 0 : 1;
        break;
      case 'tripSchema':
        countError += await handleTripValidation(values, setFieldError);
        break;
      case 'freightSchema':
        countError += (await handleFreightValidation(values, setFieldError)) ? 0 : 1;
        break;
      case 'informationSchema':
        countError += (await handleInformationValidation(values, setFieldError)) ? 0 : 1;
        break;
      case 'fullSchema':
        const main = (await handleMainValidation(values, setFieldError)) ? 0 : 1;
        const trip = await handleTripValidation(values, setFieldError);
        const freight = (await handleFreightValidation(values, setFieldError)) ? 0 : 1;
        const information = (await handleInformationValidation(values, setFieldError)) ? 0 : 1;
        countError += main + trip + freight + information;
        break;
      default:
        break;
    }

    return countError === 0;
  };

  const refreshListClient = () => {
    getClients();
  };

  const handleClose = () => {
    setGeolocationList({});
    onClose();
  };

  useEffect(() => {
    listVehicles();
    getClients();

    if (entity?.trajectories?.length > 0) {
      let aux = {};
      entity?.trajectories?.map(async (item, key) => {
        let position = {
          latitude: item.latitude,
          longitude: item.longitude,
        };

        if (item.address != null && position.latitude == null && position.longitude == null) {
          position = await requests.getGeolocationByAddress(item.address).then((data) => {
            return data.position;
          });
        }

        if (item.address != null) {
          aux[key] = {
            address: item.address,
            position: position,
          };
        }
      });

      setGeolocationList(aux);
    } else {
      setGeolocationList({});
    }

    if (containerRef.current) {
      setHeight(containerRef.current.offsetHeight); // Obtém a altura da div
    }
  }, []);

  return (
    <Grid py="15px" px="10px" maxW={'780px'} w={'100%'} ref={containerRef}>
      <Flex direction="column">
        <Text textStyle="secondaryTitle" textColor="#0F0A1D" fontWeight="medium" className="up-anim">
          {title}
        </Text>

        <Text textStyle="subtitle" textColor="#0F0A1D80" className="up-anim">
          {subTitle}
        </Text>
      </Flex>

      <Box maxH={{ lg: height - 115 + 'px' }} overflow={'auto'}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues(entity, vehicles, listVehicles)}
          validationSchema={validationSchema()}
          onSubmit={(values, { setFieldError, setSubmitting }) => {
            setSubmitting(true);

            const toastId = toast.info(`Aguarde enquanto ${entity?.identifier?.length > 0 ? 'editamos' : 'geramos'} a viagem...`, {
              autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
              closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
              draggable: false, // Impede que o usuário arraste o toast
              closeButton: false, // Não exibe o botão de fechar o toast
            });

            const auxTrajectories = [];
            values.trip?.forEach((trajectory) => {
              let fileValues = [];
              let clientValues = [];
              let latitude = trajectory.latitude;
              let longitude = trajectory.longitude;

              if (trajectory.cteNumber && Array.isArray(trajectory.cteNumber)) {
                trajectory.cteNumber.forEach((cte) => {
                  fileValues.push(cte?.value);
                });
              } else if (trajectory.cteNumber && typeof trajectory.cteNumber === 'string') {
                fileValues.push(trajectory.cteNumber);
              }

              if (trajectory.clients && Array.isArray(trajectory.clients)) {
                trajectory.clients.forEach((client) => {
                  let identifierClient;

                  if (typeof client === 'object') {
                    identifierClient = client.value;
                  } else {
                    identifierClient = client;
                  }

                  clientValues.push(identifierClient);
                });
              }

              auxTrajectories.push({
                type: trajectory.type.value,
                clients: clientValues,
                driverFreightFiles: fileValues,
                address: trajectory.address,
                observation: trajectory.observation,
                latitude: latitude ?? 0,
                longitude: longitude ?? 0,
              });
            });

            const aux = {
              ...values.main,
              ...values.freight,
              ...values.information,
              vehicle: null,
              plates: null,
              modality: values.main.modality?.value,
              typeExternalCode: values.main.typeExternalCode?.value,
              shippingCompanyName:
                values.main.shippingCompanyName.value !== 'VENDEMMIA'
                  ? values.main.shippingCompanyNameDescription
                  : values.main.shippingCompanyName.value,
              trajectories: auxTrajectories,
              driver: values?.information?.driver?.value,
              startsAt: values?.information?.startsAt ? moment(values?.information?.startsAt).format('DD/MM/yyyy HH:mm:ss') : null,
              endsAt: values?.information?.endsAt ? moment(values?.information?.endsAt).format('DD/MM/yyyy HH:mm:ss') : null,
              typeTransport: values.main.typeTransport?.value,
              subTypeTransport: values.main.subTypeTransport?.value,
              typeMandatoryFile: values.main.typeMandatoryFile?.value,
            };

            delete aux.driverFreightFiles;
            delete aux.clients;
            delete aux.vehicle;

            if (values?.information?.vehicle?.length > 0) {
              let vehicle = '';
              let plates = [];

              values?.information?.vehicle?.forEach((item, index) => {
                if (index === 0) vehicle = item?.value;
                if (!plates.includes(item?.label)) plates.push(item?.label);
              });

              aux.vehicle = vehicle;
              aux.plates = plates.join(', ');
            }

            if (processList?.length > 0) {
              aux.processes = processList;
            }

            if (entity?.identifier?.length > 0) {
              requests
                .editDriverFreight(entity?.identifier, aux)
                .then((response) => {
                  toast.dismiss(toastId); // Remove o toast exibido anteriormente
                  toast.success(`Viagem #${entity.code} editada com sucesso.`);
                  reloadList();
                  setSubmitting(false);
                  onCancelled();
                })
                .catch(() => {
                  setSubmitting(false);
                  setIsLoading(false);
                  toast.dismiss(toastId); // Remove o toast exibido anteriormente
                  toast.error(`Problemas ao editar viagem, tenve noamente ou entre em contato com o Administrador.`);
                });
            } else {
              requests
                .addDriverFreight(aux, newStatus)
                .then((response) => {
                  toast.dismiss(toastId);
                  toast.success(`Viagem #${response.code} cadastrada com sucesso`);
                  setSubmitting(false);

                  if (tab === 'progress') {
                    reloadList();
                  } else {
                    reloadKanban();
                  }

                  onCancelled();
                  handleClose();
                })
                .catch(() => {
                  toast.dismiss(toastId); // Remove o toast exibido anteriormente
                  toast.error(`Problemas ao cadastrar viagem, tenve noamente ou entre em contato com o Administrador.`);
                  setSubmitting(false);
                  handleClose();
                });
            }
          }}>
          {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting, setSubmitting, errors, setErrors, setFieldError }) => (
            <form onSubmit={handleSubmit}>
              <Flex direction="column" overflowY="auto" mt="0.625rem">
                <Accordion defaultIndex={[0]} index={step}>
                  <ScreenLoader isLoading={isLoading}>
                    <Flex gap="0.625rem" direction="column">
                      <StepRegister
                        title="Dados principais"
                        step={step}
                        stepIndex={0}
                        error={cardMainDataError}
                        onNext={() => {
                          setStep(0);
                        }}>
                        {step === 0 && (
                          <CardMainData
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            setFieldError={setFieldError}
                            validatedForm={validatedForm}
                            clientList={clientList}
                            setClientList={setClientList}
                            filterOptions={filterOptions}
                            setStep={setStep}
                            docsTripSelected={docsTripSelected}
                            setDocsTripSelected={setDocsTripSelected}
                            setDocsTrajectoriesSelected={setDocsTrajectoriesSelected}
                            geolocationList={geolocationList}
                            setGeolocationList={setGeolocationList}
                            processSelected={processList}
                            setProcessSelected={setProcessList}
                            style={{ overflow: 'initial' }}
                            setShowAlert={setShowAlert}
                            clientsEntity={entity?.clients || []}
                            refreshClients={refreshListClient}
                          />
                        )}
                      </StepRegister>

                      <StepRegister
                        title="Trechos da viagem"
                        stepIndex={1}
                        step={step}
                        error={cardTripError}
                        onNext={() => {
                          setStep(1);
                        }}>
                        {step === 1 && (
                          <CardTrip
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            setErrors={setErrors}
                            setFieldError={setFieldError}
                            validatedForm={validatedForm}
                            setStep={setStep}
                            filterOptions={filterOptions}
                            clientList={clientList}
                            geolocationList={geolocationList}
                            setGeolocationList={setGeolocationList}
                            docsTrajectoriesSelected={docsTrajectoriesSelected}
                          />
                        )}
                      </StepRegister>

                      <StepRegister
                        title="Informações do frete"
                        stepIndex={2}
                        step={step}
                        error={cardFreightError}
                        onNext={() => {
                          setStep(2);
                        }}>
                        {step === 2 && (
                          <CardFreight
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            setFieldError={setFieldError}
                            validatedForm={validatedForm}
                            setStep={setStep}
                          />
                        )}
                      </StepRegister>

                      <StepRegister
                        title="Dados gerais"
                        stepIndex={3}
                        step={step}
                        error={cardGeneralInformationError}
                        onNext={() => {
                          setStep(3);
                        }}>
                        {step === 3 && (
                          <CardGeneralInformation
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            setFieldError={setFieldError}
                            validatedForm={validatedForm}
                            setStep={setStep}
                            listVehicles={listVehicles}
                            vehicles={vehicles}
                          />
                        )}
                      </StepRegister>
                    </Flex>
                  </ScreenLoader>
                </Accordion>
              </Flex>

              <Flex
                bg={'#fff'}
                p={'25px'}
                position={'fixed'}
                bottom={'0'}
                left={'0'}
                w={'100%'}
                zIndex={'2'}
                flexDirection={{ base: 'column', md: 'row' }}
                justify="space-between"
                gap={{ base: '2', md: '2' }}
                mt="0.625rem">
                {!entity?.identifier && (
                  <Button
                    leftIcon={<MdOutlineInsertDriveFile />}
                    type="button" // Garanta que o tipo do botão não seja 'submit'
                    onClick={async () => {
                      if (await validatedForm(values, setFieldError)) {
                        setNewStatus('draft');
                        handleSubmit();
                      }
                    }}
                    border="1px"
                    px="20px"
                    py="9px"
                    color="#5289C9"
                    borderColor="#5289C9"
                    bgColor="transparent"
                    loadingText="Salvando..."
                    isDisabled={isSubmitting && newStatus == 'created'}
                    isLoading={isSubmitting && newStatus == 'draft'}>
                    Salvar como rascunho
                  </Button>
                )}

                <Box
                  display={{ base: 'grid', md: 'flex' }}
                  gridTemplateColumns={{ base: '1fr 1fr', md: 'none' }}
                  gap={{ base: '10px', md: '20px' }}>
                  <Button
                    variant="secundary"
                    px="30px"
                    py="9px"
                    onClick={() => {
                      onCancelled();
                    }}
                    isDisabled={isSubmitting}>
                    Cancelar
                  </Button>

                  <ModalFreightConfirm
                    values={values}
                    load={isSubmitting}
                    setSubmitting={setSubmitting}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={handleClose}
                    handleSubmit={handleSubmit}
                    newStatus={newStatus}
                    setNewStatus={setNewStatus}
                    filterOptions={filterOptions}
                    clientList={clientList}
                    validatedForm={validatedForm}
                    setFieldError={setFieldError}
                    externalDriver={entity?.externalDriverName}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    showAlert={showAlert}
                  />
                </Box>
              </Flex>
            </form>
          )}
        </Formik>
      </Box>
    </Grid>
  );
};

export default RegisterFreight;
