import React, { useState } from 'react';

import { FieldArray, useFormikContext } from 'formik';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd, MdMarkEmailRead, MdOutgoingMail, MdVisibility } from 'react-icons/md';

import { Box, Button, Divider, Flex, Grid, Input, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import { DynamicDebouncedSelectField } from '../../../../components/CommonForm/Fields/DynamicDebouncedSelectField';
import { DynamicHiddenField } from '../../../../components/CommonForm/Fields/DynamicHiddenField';
import { DynamicInputField } from '../../../../components/CommonForm/Fields/DynamicInputField';
import { CommonModal } from '../../../../components/CommonModal/CommonModal';
import { hexToRgba } from '../../../../utils/colors/hexToRgba';
import { renderContactFields } from '../Helpers/renderContactFields';

const statusColors = {
  ENVIADO: '#4CAF50',
  EM_RASCUNHO: '#FFC107',
  PENDENTE: '#FF9800',
  CANCELADO: '#F44336',
};

const getStatusColor = (status) => statusColors[status] || '#FFFFFF';

export const DynamicFormProtestLetters = ({
  detailsProtest,
  suppliersList = [],
  processList = [],
  showDocument,
  sendEmail,
  fetchOptionsProcess,
  fetchOptionsSuppliers,
  disabledButtons,
  type,
  drawerEditOnClose,
  handleOpenEditModal,
}) => {
  const { values, setFieldValue, submitForm } = useFormikContext();
  const [currentItem, setCurrentItem] = useState();
  const [switchCurrent, setSwitchCurrent] = useState(false);

  //modals methods
  const { isOpen: modalConfirmIsOpen, onOpen: modalConfirmOnOpen, onClose: modalConfirmOnClose } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();

  // State para controle de desativação dos botões de envio de e-mail
  const [disabledEmails, setDisabledEmails] = useState([]);

  const inactiveFieldProps = { readOnly: true, disabled: true };

  const suppliersWithFilesSent = detailsProtest?.suppliersWithFilesSent;
  const protestStatus = detailsProtest?.status;

  const addSupplier = () => {
    const newSupplier = { documentNumberFormatted: '', name: '', contacts: [], wasAdded: true };
    setFieldValue('suppliers', [...(values.suppliers || []), newSupplier]);
  };

  const removeSupplier = (index) => {
    setFieldValue(
      'suppliers',
      values.suppliers.filter((_, i) => i !== index)
    );
  };

  const handleAutoCompleteSupplierFields = (selectedSupplier, supplierIndex) => {
    setSwitchCurrent(true);
    setFieldValue(`suppliers[${supplierIndex}].identifier`, selectedSupplier.identifier);
    setFieldValue(`suppliers[${supplierIndex}].documentNumberFormatted`, selectedSupplier.documentNumberFormatted);
    setFieldValue(`suppliers[${supplierIndex}].name`, selectedSupplier.name || '');
    setFieldValue(`suppliers[${supplierIndex}].address`, selectedSupplier.address || '');
    setFieldValue(
      `suppliers[${supplierIndex}].contacts`,
      selectedSupplier.contacts?.map(({ name, email }) => ({ name, email })) || []
    );
  };

  const handleResetSuplierFields = (supplierIndex) => {
    setFieldValue(`suppliers[${supplierIndex}].identifier`, '');
    setFieldValue(`suppliers[${supplierIndex}].documentNumberFormatted`, '');
    setFieldValue(`suppliers[${supplierIndex}].name`, '');
    setFieldValue(`suppliers[${supplierIndex}].address`, '');
    setFieldValue(`suppliers[${supplierIndex}].contacts`, []);
  };

  // Função para desativar o botão de e-mail após o clique
  const handleSendEmailClick = async () => {
    setDisabledEmails([...disabledEmails, currentItem]); // Adiciona o índice ao array de botões desativados

    await sendEmail(currentItem);
    /* detailsProtest.suppliersWithFilesSent.push(values.suppliers[currentItem].identifier); */
    // Atualiza o status para "ENVIADO" caso todos os emails tenham sido enviados em todos os fornecedores
    drawerEditOnClose();
    /* handleOpenEditModal(values.processIdentifier); */
    /* load(); */
  };

  const renderSupplierFields = (supplier, index) => {
    const { contacts, documentNumberFormatted, wasAdded } = supplier;

    const hasContacts = contacts.length > 0;
    const hasSupplier = documentNumberFormatted?.trim() !== '';

    return (
      <Grid key={index} borderWidth="1px" borderRadius="md" borderColor="#70707036" p={4} mb={4} position="relative">
        <DynamicHiddenField props={{ name: `suppliers[${index}].identifier` }} />

        <Grid gridTemplateColumns="1fr 1fr" columnGap="40px">
          <DynamicDebouncedSelectField
            props={{
              name: `suppliers[${index}].documentNumberFormatted`,
              placeholder: 'CNPJ do Fornecedor',
              list: suppliersList,
              keyProp: 'identifier',
              displayProp: `displayFormatted`,
              debounceTime: 800,
              fetchOptionsSearch: fetchOptionsSuppliers,
              callback: handleAutoCompleteSupplierFields,
              reset: handleResetSuplierFields,
              index,
            }}
          />

          <DynamicInputField props={{ name: `suppliers[${index}].name`, placeholder: 'Nome do Fornecedor', ...inactiveFieldProps }} />
        </Grid>

        <DynamicInputField props={{ name: `suppliers[${index}].address`, placeholder: 'Endereço', ...inactiveFieldProps }} />

        <Flex justifyContent="space-between">
          {type === 'edit' && (
            <Flex alignItems="center" gap={2}>
              {hasSupplier && !wasAdded && !switchCurrent && (
                <Tooltip label="Visualizar" fontSize="md">
                  <Button variant="iconDashed" type="button" onClick={() => showDocument(index)}>
                    <Box>
                      <MdVisibility size={20} />
                    </Box>
                  </Button>
                </Tooltip>
              )}
              {hasContacts && !wasAdded && !switchCurrent && (
                <>
                  <Tooltip label="Enviar e-mail" fontSize="md">
                    <Button
                      variant="iconDashed"
                      type="button"
                      onClick={() => (modalConfirmOnOpen(), setCurrentItem(index))}
                      isDisabled={disabledEmails.includes(index) || disabledButtons.includes(index)}>
                      <Flex gap={'10px'}>
                        {suppliersWithFilesSent.includes(values.suppliers[index].identifier) ? (
                          <>
                            <MdMarkEmailRead size={20} />
                            <Text>E-mail enviado com sucesso para o fornecedor.</Text>
                          </>
                        ) : (
                          <MdOutgoingMail size={20} />
                        )}
                      </Flex>
                    </Button>
                  </Tooltip>
                </>
              )}
            </Flex>
          )}
          <Flex alignItems="center" gap={2}>
            <Button variant="remove" type="button" onClick={openDeleteModal}>
              <Tooltip label="Remover fornecedor" fontSize="md">
                <Flex align="center" gap="10px" p="2px 8px" color="#ec7063" fontSize="14px">
                  <FaTrashAlt color="#ec7063" size={16} />
                </Flex>
              </Tooltip>
            </Button>
          </Flex>

          <CommonModal
            heading="Deseja mesmo remover esse fornecedor?"
            subtitle="Ele será removido dessa operação apenas."
            callback={() => removeSupplier(index)}
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            variant="caution"
            size="md"
            removeRadius
          />
        </Flex>

        {hasContacts && <Divider m="20px 0" />}

        <FieldArray name={`suppliers[${index}].contacts`} render={() => renderContactFields(contacts, index, inactiveFieldProps)} />

        <CommonModal
          heading={'Certeza que deseja enviar os e-mails para os contatos cadastrados?'}
          callback={handleSendEmailClick}
          isOpen={modalConfirmIsOpen}
          onOpen={modalConfirmOnOpen}
          onClose={modalConfirmOnClose}
          variant={'caution'}
          size={'md'}
          removeRadius={true}
        />
      </Grid>
    );
  };

  const statusStyles = {
    color: `${getStatusColor(values.status)} !important`,
    border: `1px dashed ${getStatusColor(values.status)} !important`,
    backgroundColor: `${hexToRgba(getStatusColor(values.status), 0.2)} !important`,
    readOnly: true,
    maxWidth: 'max-content',
    maxHeight: '40px !important',
    textAlign: 'center',
    p: '12px !important',
    _focusVisible: {
      boxShadow: 'none',
    },
  };

  function getStatusTranslation(status) {
    let translation;

    switch (status) {
      case 'ENVIADO':
        translation = 'ENVIADO';
        break;
      case 'EM_RASCUNHO':
        translation = 'EM RASCUNHO';
        break;
      case 'PENDENTE':
        translation = 'PENDENTE';
        break;
      case 'CANCELADO':
        translation = 'CANCELADO';
        break;
      default:
        translation = 'STATUS DESCONHECIDO';
        break;
    }

    return translation;
  }

  return (
    <>
      <DynamicHiddenField props={{ name: `processIdentifier` }} />

      <Input mb={4} sx={statusStyles} value={getStatusTranslation(values.status)} isReadOnly />

      <Grid gridTemplateColumns="1fr 1fr" gap="40px">
        <DynamicDebouncedSelectField
          props={{
            name: 'process',
            placeholder: 'Selecione o Processo',
            list: processList,
            keyProp: 'identifier',
            displayProp: 'code',
            debounceTime: 800,
            fetchOptionsSearch: fetchOptionsProcess,
          }}
        />

        <DynamicInputField props={{ name: `date`, placeholder: 'Data de Contestação', type: 'date' }} />
      </Grid>

      {protestStatus !== 'CANCELADO' && (
        <FieldArray
          name="suppliers"
          render={() => (
            <>
              {values.suppliers.map((supplier, index) => renderSupplierFields(supplier, index))}

              <Button variant="add" type="button" onClick={addSupplier} alignSelf={'baseline'}>
                <MdAdd /> Adicionar Fornecedor
              </Button>
            </>
          )}
        />
      )}
    </>
  );
};
