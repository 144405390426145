import FloatActionButton from '../Generic/FloatActionButton';
import Layout from '../Layout';
import Menubar from '../MenuBar/Menubar';
import ModalCSV from '../Modal/CSV/ModalCSV';
import ModalUploadWithButton from '../Modal/Upload/ModalUploadWithButton';
import ScreenLoader from '../ScreenLoader/ScreenLoader';

import { CommonPageProvider } from './contexts/CommonPageContext';
import { useCommonPageProps } from './Hooks/useCommonPageProps';

const CommonPage = ({ children, ...props }) => {
  const {
    screen,
    title,
    detailsTitle,
    nameForm,
    breadcrumbIcon,
    breadcrumbs,
    disableMultiUpload,
    disableAdditionalFields,
    allowFiltersOverride,
    filterOptions,
    textFilterPlaceholder,
    isRefreshLoading,
    showRefreshData,
    hideAllFilters,
    showFilters,
    showPeriodFilter,
    showTextFilter,
    additionalFormFields,
    isLoading, // Loading para tela inteira
    FAB,
    useOwnState,
    ownState,
    setOwnState,
    refreshSeconds,
    showScreenshot,
    refScreen,
    ScreenShotIsDisabled,
    shouldIgnoreFilters,
    pageRef,
    isOpenFloatButton,
    setIsOpenFloatButton,
    getFilters,
    getFiltersKey,
    onChangePeriod,
    onChangeFilters,
    onChangeTextFilter,
    onChangeshouldIgnoreFilters,
    refresh,
  } = useCommonPageProps({ ...props, children });

  return (
    <CommonPageProvider {...props}>
      <ScreenLoader isLoading={isLoading}>
        <Layout>
          <Menubar
            screen={screen}
            title={title}
            detailsTitle={detailsTitle}
            icon={breadcrumbIcon}
            linkTree={breadcrumbs}
            allowFiltersOverride={allowFiltersOverride}
            shouldIgnoreFilters={shouldIgnoreFilters}
            filterOptions={filterOptions}
            onChangeFilters={onChangeFilters}
            hideAllFilters={hideAllFilters}
            showFilters={showFilters}
            showPeriodFilter={showPeriodFilter}
            showTextFilter={showTextFilter}
            onChangePeriod={onChangePeriod}
            placeholder={textFilterPlaceholder}
            showRefreshData={showRefreshData}
            isRefreshLoading={isRefreshLoading}
            triggerRefreshData={refresh}
            refreshSeconds={refreshSeconds}
            onChangeshouldIgnoreFilters={onChangeshouldIgnoreFilters}
            onChangeTextFilter={onChangeTextFilter}
            refScreen={refScreen}
            ScreenShotIsDisabled={ScreenShotIsDisabled}
            showScreenshot={showScreenshot}
          />
          <div ref={pageRef}>
            {children}

            {FAB && FAB.length > 0 && (
              <FloatActionButton
                options={FAB.map((item, index) => {
                  if (item.modality === 'export-csv') {
                    return (
                      <ModalCSV
                        key={index}
                        title={item.title}
                        text={item.text}
                        downloadAction={item.action}
                        filters={getFilters()}
                        filtersKey={getFiltersKey()}
                        setIsOpenFloatButton={setIsOpenFloatButton}
                      />
                    );
                  }

                  if (item.modality === 'export-files') {
                    return (
                      <ModalCSV
                        key={index}
                        title={item.title}
                        text={item.text}
                        downloadAction={item.action}
                        filters={getFilters()}
                        filtersKey={getFiltersKey()}
                        setIsOpenFloatButton={setIsOpenFloatButton}
                      />
                    );
                  }

                  if (item.modality === 'upload-files') {
                    return (
                      <ModalUploadWithButton
                        key={index}
                        title={item.title}
                        text={item.text}
                        uploadAction={item.action}
                        setIsOpenFloatButton={setIsOpenFloatButton}
                        useOwnState={useOwnState}
                        ownState={ownState}
                        setOwnState={setOwnState}
                        disableAdditionalFields={disableAdditionalFields}
                        disableMultiUpload={disableMultiUpload}
                        nameForm={nameForm}
                        additional={additionalFormFields}
                      />
                    );
                  }

                  if (item.modality === 'custom') {
                    return item.action;
                  }

                  return;
                })}
                isOpen={isOpenFloatButton}
                setIsOpen={setIsOpenFloatButton}
              />
            )}
          </div>
        </Layout>
      </ScreenLoader>
    </CommonPageProvider>
  );
};

export default CommonPage;
