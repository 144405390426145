import React, { useEffect, useState } from 'react';

import {
  MdCheck,
  MdClose,
  MdKeyboardArrowRight,
  MdOutlineCreateNewFolder,
  MdOutlineFolderCopy,
  MdOutlineModeEditOutline,
} from 'react-icons/md';

import {
  ButtonGroup,
  Card,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Grid,
  HStack,
  Heading,
  IconButton,
  Input,
  Select,
  Switch,
  Text,
  Textarea,
  Tooltip,
  useEditableControls,
} from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

const ReportSql = ({ dataReport, sidebarMenu, isNewReport, unsavedChangesExist, unsavedChanges }) => {
  const [listDatabaseNames, setListDatabaseNames] = useState([]);
  const [menu, setMenu] = useState([]);

  const [isLoadingListDatabaseNames, setIsLoadingListDatabaseNames] = useState(true);

  const [reportName, setReportName] = useState('');
  const [reportFolder, setReportFolder] = useState('');
  const [reportType, setReportType] = useState();
  const [reportQuery, setReportQuery] = useState();

  const [switchFolder, setswitchFolder] = useState();

  function EditableControls() {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton icon={<MdCheck color="#A885F9" />} {...getSubmitButtonProps()} />
        <IconButton icon={<MdClose color="#A885F9" />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton size="sm" icon={<MdOutlineModeEditOutline bg="transparent" color="#A885F9" />} {...getEditButtonProps()} />
      </Flex>
    );
  }

  const handleDatabaseFieldChange = (e, field) => {
    const newValue = e.target.value;

    switch (field) {
      case 'name':
        setReportName(newValue);
        break;
      case 'type':
        setReportType(newValue);
        break;
      case 'sql':
        setReportQuery(newValue);
        break;
      case 'folder':
        setReportFolder(newValue);
        break;
      default:
        break;
    }
  };

  const loadListDatabaseNames = async () => {
    setIsLoadingListDatabaseNames(true);

    try {
      const res = await requests.getListDatabaseNames();
      setListDatabaseNames(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    setIsLoadingListDatabaseNames(false);
  };

  const setLocalStorage = (data) => {
    localStorage.setItem('report-sql', JSON.stringify(data));
  };

  /* const load = () => {}; */

  useEffect(() => {
    loadListDatabaseNames();

    setReportName(dataReport?.name);
    setReportFolder(dataReport?.folder);
    setReportType(dataReport?.databaseName);
    setReportQuery(dataReport?.query);
  }, [dataReport]);

  useEffect(() => {
    isNewReport ? setswitchFolder(true) : setswitchFolder(false);
  }, [isNewReport]);

  useEffect(() => {
    setMenu(sidebarMenu);
  }, [sidebarMenu]);

  /* useEffect(() => {
    setUnsavedChangesExist(true);
  }, [reportName, reportType, reportQuery]); */

  useEffect(() => {
    setLocalStorage({
      name: reportName,
      folder: reportFolder,
      query: reportQuery,
      databaseName: reportType,
    });
  }, [reportName, reportType, reportQuery, reportFolder]);

  return (
    <Flex flexDirection={'column'} h={'100%'} gap={'15px'}>
      <style>
        {`
          .chakra-editable {
            text-indent: 16px;
          }
        `}
      </style>
      <Card as="section" bg={'white'} h={'100%'}>
        <Flex
          as="header"
          borderBottom={'1px solid #0F0A1D26'}
          minH={'68px'}
          justifyContent={'space-between'}
          transition={'padding 0.5s ease 0s'}
          align={'center'}
          mb={'20px'}
          p={'0 26px'}>
          <Heading
            display={'flex'}
            gap={'15px'}
            align={'center'}
            fontSize={'20px'}
            color={'primary'}
            fontWeight={'bold'}
            alignItems={'center'}
            fontFamily={'Hanken Grotesk'}>
            {isNewReport ? <>Criando nova Consulta SQL</> : <>Consulta {reportName}</>}
          </Heading>
        </Flex>
        <Flex p={'0 15px 26px'} flexDirection={'column'} gap="10px">
          <Flex as="header" justifyContent={'space-between'} transition={'padding 0.5s ease 0s'} align={'center'}>
            <Flex align={'center'} gap={'10px'} w={'full'}>
              <Flex w={'full'}>
                {switchFolder && (
                  <Editable
                    textAlign="center"
                    value={reportFolder}
                    isPreviewFocusable={false}
                    selectAllOnFocus={false}
                    placeholder="Escreva o nome da pasta"
                    display={'flex'}
                    minW={'100%'}
                    minH={'40px'}
                    borderBottom={'1px solid #6C48C2'}
                    fontSize={'15px'}
                    justifyContent={'space-between'}
                    color="#A885F9"
                    alignItems="center"
                    startWithEditView={isNewReport ? true : false}>
                    <EditablePreview />
                    <Input
                      as={EditableInput}
                      _focus={{
                        boxShadow: 'none !important',
                        borderColor: '#A885F9 !important',
                        border: '0',
                      }}
                      onChange={(e) => {
                        handleDatabaseFieldChange(e, 'folder');
                        unsavedChanges(true);
                      }}
                    />
                    <EditableControls />
                  </Editable>
                )}
                {!switchFolder && (
                  <Select
                    value={reportFolder}
                    color="#A885F9"
                    borderColor="#A885F9"
                    icon={<MdKeyboardArrowRight size="30" color="#A885F9" />}
                    variant="flushed"
                    fontSize={'15px'}
                    onChange={(e) => {
                      handleDatabaseFieldChange(e, 'folder');
                      unsavedChanges(true);
                    }}>
                    <option value="-">Selecione uma pasta</option>
                    {menu &&
                      menu.map((entity, key) => (
                        <option key={key} value={entity.value}>
                          {entity.name}
                        </option>
                      ))}
                  </Select>
                )}
              </Flex>
              <>
                <Flex align={'center'} gap={'10px'}>
                  <Flex height={'30px'} width={'1px'} background={'#70707036'} m={'0 10px'}></Flex>
                </Flex>
                <Flex align={'center'} gap={'10px'}>
                  <Tooltip hasArrow label="Criar nova pasta" aria-label="A tooltip">
                    <span>
                      <MdOutlineCreateNewFolder size={18} color={switchFolder ? '#8067DC' : '#70707036'} />
                    </span>
                  </Tooltip>
                  <HStack>
                    <style>
                      {`
                        [data-toggle-folder] .chakra-switch__track{
                          background-color: #7F3AFC;
                        }

                        .chakra-select {
                          padding: 0 15px;
                        }

                        .chakra-select option{
                          padding: 10px 0;
                        }
                      `}
                    </style>
                    <Switch
                      isFocusable={true}
                      size="md"
                      bottom="2px"
                      colorScheme="purple"
                      data-toggle-folder="true"
                      onChange={() => {
                        setswitchFolder(!switchFolder);
                      }}
                      isChecked={!switchFolder}
                    />
                  </HStack>
                  <Tooltip hasArrow label="Escolher pasta da lista" aria-label="A tooltip">
                    <span>
                      <MdOutlineFolderCopy size={18} color={!switchFolder ? '#8067DC' : '#70707036'} />
                    </span>
                  </Tooltip>
                </Flex>
              </>
            </Flex>
          </Flex>
          <Grid templateColumns={{ base: '1fr', xlr: '200px 1fr' }} gap={'15px'}>
            <ScreenLoader isLoading={isLoadingListDatabaseNames}>
              <Select
                value={reportType}
                color="#A885F9"
                borderColor="#A885F9"
                icon={<MdKeyboardArrowRight size="30" color="#A885F9" />}
                variant="flushed"
                onChange={(e) => {
                  handleDatabaseFieldChange(e, 'type');
                  unsavedChanges(true);
                }}>
                <option value="-">Base da dados</option>
                {listDatabaseNames &&
                  listDatabaseNames.map((entity, key) => (
                    <option key={key} value={entity.value}>
                      {entity.name}
                    </option>
                  ))}
              </Select>
            </ScreenLoader>
            <Editable
              textAlign="center"
              value={reportName}
              isPreviewFocusable={false}
              selectAllOnFocus={false}
              placeholder="Escreva o nome do seu relatório"
              display={'flex'}
              borderBottom={'1px solid #6C48C2'}
              fontSize={'15px'}
              justifyContent={'space-between'}
              color="#A885F9"
              p={{ base: '4px 0', md: '0' }}
              alignItems="center">
              <EditablePreview />
              <Input
                as={EditableInput}
                _focus={{
                  boxShadow: 'none !important',
                  borderColor: '#A885F9 !important',
                  border: '0',
                }}
                onChange={(e) => {
                  handleDatabaseFieldChange(e, 'name');
                  unsavedChanges(true);
                }}
              />
              <EditableControls />
            </Editable>
          </Grid>
          <Textarea
            minH={'340px'}
            placeholder="SELECT * FROM table LIMIT 0, 10;"
            value={reportQuery}
            border="1px solid #A885F9"
            fontSize={'15px'}
            resize={'none'}
            _focus={{
              boxShadow: 'none !important',
              borderColor: '#A885F9 !important',
            }}
            onChange={(e) => {
              handleDatabaseFieldChange(e, 'sql');
              unsavedChanges(true);
            }}
          />
          {unsavedChangesExist && (
            <Text color="#7D7D7D" fontSize={'11px'}>
              Existem mudanças não salvas
            </Text>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};

export default ReportSql;
