import React from 'react';

import { ErrorMessage, Field, useFormikContext } from 'formik';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';

import { FormControl, FormLabel, Grid, Input, Text } from '@chakra-ui/react';

import InputCurrency from '../../../../../components/Form/Input/InputCurrency';

const DynamicFormTripEdit = () => {
  const { setFieldValue } = useFormikContext();

  const labels = {
    endsAt: 'Previsão de chegada',
    merchandiseValue: 'Valor da mercadoria',
    freightValue: 'Frete',
    weight: 'Peso (KG)',
  };

  return (
    <Grid gridTemplateColumns="1fr 1fr" gap="12px 25px">
      <FormControl mb={4}>
        <FormLabel htmlFor="endsAt">{labels.endsAt}</FormLabel>
        
        <Field name="endsAt">
          {({ field }) => {
            const formattedValue = moment(field.value, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm');
            return (
              <>
                <Input
                  type="datetime-local"
                  id="endsAt"
                  name="endsAt"
                  value={formattedValue}
                  onChange={(e) => {
                    const originalValue = moment(e.target.value).format('DD/MM/YYYY HH:mm');
                    setFieldValue('endsAt', originalValue);
                  }}
                />
              </>
            );
          }}
        </Field>
      </FormControl>

      <FormControl mb={4}>
        <FormLabel htmlFor="merchandiseValue">{labels.merchandiseValue}</FormLabel>
        <Field name="merchandiseValue">
          {({ field }) => (
            <InputCurrency
              name="merchandiseValue"
              placeholder="R$0,00"
              value={field.value}
              setFieldValue={setFieldValue}
              isFormattedValue={false}
            />
          )}
        </Field>
        <ErrorMessage name="merchandiseValue" component={Text} className="error-message-error" />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel htmlFor="freightValue">{labels.freightValue}</FormLabel>
        <Field name="freightValue">
          {({ field }) => (
            <InputCurrency
              name="freightValue"
              placeholder="R$0,00"
              value={field.value}
              setFieldValue={setFieldValue}
              isFormattedValue={false}
            />
          )}
        </Field>
        <ErrorMessage name="freightValue" component={Text} className="error-message-error" />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel htmlFor="weight">{labels.weight}</FormLabel>
        <Field name="weight">
          {({ field }) => (
            <NumericFormat
              placeholder="Preencha o peso (KG)"
              className="custom-input-mask"
              type="text"
              displayType="input"
              thousandSeparator="."
              decimalSeparator=","
              thousandsGroupStyle="thousand"
              id="weight"
              name="weight"
              value={field.value}
              decimalScale={2}
              allowNegative={true}
              fixedDecimalScale={true}
              onValueChange={(values) => {
                const { floatValue } = values;
                setFieldValue('weight', floatValue);
              }}
            />
          )}
        </Field>
        <ErrorMessage name="weight" component={Text} className="error-message-error" />
      </FormControl>
    </Grid>
  );
};

export default DynamicFormTripEdit;
