import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import moment from 'moment';
import { MdOutlineShare } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import 'react-step-progress-bar/styles.css';
import { toast } from 'react-toastify';

import {
  Button,
  Flex,
  Grid,
  Image,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import NoPicture from '../../../assets/images/icons/driver-without-picture.png';
import Card from '../../../components/Card/Card';
import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import { DynamicFormButton } from '../../../components/CommonForm';
import { DynamicForm } from '../../../components/CommonForm/DynamicForm';
import { DynamicFormFooter } from '../../../components/CommonForm/DynamicFormFooter';
import FloatActionButton from '../../../components/Generic/FloatActionButton';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import DefaultModal from '../../../components/Modal/Template/DefaultModal';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { processCheck, processUncheck } from '../../../utils/exports';
import { executeRequest } from '../../../utils/requests/executeRequest';
import { limitChars } from '../../../utils/strings';
import { capitalizeWords } from '../../../utils/text/format/capitalizeWords';
import { descriptionStatusTrip, getStatusTransition } from '../../../utils/translate';
import ModalFreight from '../TravelManagement/components/TravelList/ModalFreight';

import CommentTab from './components/Comment/CommentTab';
import DocumentTab from './components/Document/DocumentTab';
import DynamicFormTripEdit from './components/Drawers/DynamicFormTripEdit';
import LinkListDrawer from './components/Drawers/LinkListDrawer';
import EventsTab from './components/Events/EventsTab';
import InformationManagement from './components/Information/InformationManagement';
import InformationTab from './components/Information/InformationTab';
import MapTrajectory from './components/Map/MapTrajectory';
import TrackingTab from './components/Tracking/TrackingTab';
import TrajectoryTab from './components/Trajectory/TrajectoryTab';

const yup = require('yup');

const TravelDetailPage = () => {
  const { identifier } = useParams();
  let { state } = useLocation();
  let { viewport } = useResponsiveCheck();

  let profileRole = localStorage.getItem('profile-role');

  const [entity, setEntity] = useState(state);
  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isLoadingCheckpoints, setIsLoadingCheckpoints] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventsFull, setEventsFull] = useState([]);
  const [checkpoints, setCheckpoints] = useState([]);
  const [lastVersionApp, setLastVersionApp] = useState('Não encontrado');
  const [additionalInformation, setAdditionalInformation] = useState({});
  const [tripPercentage, setTripPercentage] = useState();
  const [currentDriverPosition, setCurrentDriverPosition] = useState({});
  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [load, setLoad] = useState(false);
  const [action, setAction] = useState(1);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [zoom, setZoom] = useState(5);
  const [center, setCenter] = useState({
    lat: -23.5792551,
    lng: -46.641581,
  });

  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure();
  const { isOpen: isOpenFinish, onOpen: onOpenFinish, onClose: onCloseFinish } = useDisclosure();
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const { isOpen: isOpenModalLink, onOpen: onOpenModalLink, onClose: onCloseModalLink } = useDisclosure();
  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure();

  const calcCarbon = entity?.carbonTrack?.calculated + entity?.carbonTrack?.retired;
  const calcCarbonFormatted = calcCarbon.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

  const mainRef = useRef(null);
  const containerRef = useRef(null);
  const travelRef = useRef(null);

  const [height, setHeight] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [travelHeight, setTravelHeight] = useState(0);

  const validationSchema = yup.object().shape({
    endsAt: yup.string().required('Campo obrigatório'),
    merchandiseValue: yup.string().required('Campo obrigatório'),
    freightValue: yup.string().required('Campo obrigatório'),
    weight: yup.string().required('Campo obrigatório'),
  });

  const onSubmit = async (action, description, onClose) => {
    setLoad(true);

    requests
      .addFreightComment(entity.identifier, { description: description })
      .then(async () => {
        toast.success(`Comentário da viagem #${entity?.code} enviado com sucesso!`);
        requests
          .changeStatusDriverFreight(entity?.identifier, action)
          .then((response) => {
            toast.success(`Viagem #${entity?.code} alterada com sucesso`);
            getEntity();
            onClose();
            setLoad(false);
          })
          .catch(() => {
            toast.error(`Problemas ao alterar a viagem #${entity?.code}, tente novamente ou entre em contato com o Administrador`);
            setLoad(false);
          });
      })
      .catch(() => {
        toast.error(`Erro ao enviar o comentário da viagem #${entity?.code} , tente novamente.`);
        setLoad(false);
      });
  };

  const percentage = (modality, status, typeExternalCode) => {
    let text;

    if (status === 'COMPLETED') {
      text = 'Viagem concluída';
    } else if (modality === 'FRETE_NAO_RASTREAVEL') {
      text = 'Porcentagem estimada de conclusão com base nos horários previstos';
    } else if (modality === 'APP_MOTORISTA' || typeExternalCode === 'OPENTECH') {
      text = 'Porcentagem estimada de conclusão com base na distância';
    } else {
      text = 'Carregando...';
    }

    return text;
  };

  const getEntity = () => {
    setIsLoadingEntity(true);

    requests
      .showDriverFreight(identifier)
      .then((response) => {
        setEntity(response);
        updateInitialValues(response);
      })
      .finally(() => setIsLoadingEntity(false));
  };

  const updateInitialValues = (entity) => {
    const updatedValues = {
      ...entity,
      endsAt: entity.endsAtFormatted,
      merchandiseValue: entity?.merchandiseValue,
      freightValue: entity?.freightValue,
      weight: entity?.weight,
    };

    setInitialValues(updatedValues);
  };

  const getEvents = () => {
    setIsLoadingEvents(true);

    requests
      .showEventsDriverFreight(identifier)
      .then((response) => setEvents(response))
      .finally(() => setIsLoadingEvents(false));
  };

  const getEventsFull = () => {
    // setIsLoadingEventsFull(true);
    requests
      .showEventsDriverFreight(identifier, 'full')
      .then((response) => {
        setEventsFull(response);
      })
      .finally(() => {
        // setIsLoadingEventsFull(false);
      });
  };

  const getCheckpoints = () => {
    setIsLoadingCheckpoints(true);
    requests
      .showCheckpointsDriverFreight(entity.identifier)
      .then((response) => {
        getMetrics(entity?.identifier);

        if (response?.checkpoints?.length > 0) {
          setCheckpoints(response?.checkpoints);

          if (entity?.modality == 'APP_MOTORISTA' && response?.lastVersionApp != null) {
            setLastVersionApp(response?.lastVersionApp);
          }
        }
      })
      .finally(() => {
        setIsLoadingCheckpoints(false);
      });
  };

  const getMetrics = (identifier) => {
    requests.metricsTrip(identifier).then((response) => {
      setAdditionalInformation(response);
    });
  };

  const getCurrentDriverPosition = () => {
    requests
      .getCurrentDriverPosition(identifier)
      .then((res) => setCurrentDriverPosition(res))
      .catch(() => {})
      .finally(() => {});
  };

  const fetchPercentageCompletion = async (identifier) => {
    try {
      const response = await requests.tripPercentage(identifier);
      setTripPercentage(response);
    } catch (error) {
      console.error('Erro ao buscar o percentual de conclusão:', error);
    }
  };

  const handleClickChangeStatus = (event) => {
    setIsLoadingEntity(true);
    let transition = getStatusTransition(entity?.status, event?.target?.value);
    if (!transition) {
      toast.error('Não foi possivel alterar o status da viagem, verifique o fluxograma para alteração de status');
      setIsLoadingEntity(false);
      return;
    }

    requests
      .changeStatusDriverFreight(entity?.identifier, transition)
      .then(() => {
        toast.success(
          `Status alterado de ${descriptionStatusTrip(entity?.status)} para ${descriptionStatusTrip(event?.target?.value)}`
        );
        window.location.reload();
      })
      .catch(() => {
        toast.error('Problemas ao alterar status da viagem, tente novamente ou entre em contato com o Administrador');
        setIsLoadingEntity(false);
      });
  };

  const checkStatus = (action, status) => {
    const cancel = ['DRAFT', 'CREATED', 'SENT_TO_DRIVER', 'ACCEPTED_BY_DRIVER', 'TRAVELLING'];
    const complete_travel = [
      'DELIVERED_WITH_SIGNATURE',
      'DELIVERED_WITHOUT_SIGNATURE',
      'SENT_TO_DRIVER',
      'ACCEPTED_BY_DRIVER',
      'TRAVELLING',
    ];

    switch (action) {
      case 'cancel':
        return cancel.indexOf(status) !== -1 ? true : false;
      case 'complete_travel':
        return complete_travel.indexOf(status) !== -1 ? true : false;
      default:
        return false;
    }
  };

  const checkPlate = () => {
    if (entity?.plates) {
      return entity?.plates;
    } else if (entity?.vehicle?.licensePlate) {
      return entity?.vehicle?.licensePlate;
    } else {
      return 'Não informado';
    }
  };

  const plates = checkPlate();

  const hiddenStatuses = ['DRAFT', 'REFUSED_BY_DRIVER', 'REFUSED_BY_DRIVER_HANDLED', 'HALTED', 'HALTED_HANDLED', 'CANCELLED'];

  const canShowEditButton = (status) => {
    if (!permissions.logisticTripEdit) {
      return false;
    }

    switch (status) {
      case 'DRAFT':
      case 'CREATED':
      case 'SENT_TO_DRIVER':
      case 'REFUSED_BY_DRIVER':
      case 'REFUSED_BY_DRIVER_HANDLED':
      case 'ACCEPTED_BY_DRIVER':
      case 'GOING_TO_COLLECT':
      case 'READ_TO_COLLECT':
      case 'COLLECTING':
      case 'READ_TO_TRAVEL':
      case 'TRAVELLING':
        return true;
      default:
        return false;
    }
  };

  const onSubmitForm = (values) => {
    handleEditTrip(identifier, values);
  };

  const { triggerRefresh } = useForceRefresh(getEntity);

  const handleEditTrip = async (identifier, values) => {
    const newValues = {
      endsAt: moment(values?.endsAt, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm:ss'),
      merchandiseValue: values?.merchandiseValue,
      freightValue: values?.freightValue,
      weight: values?.weight,
    };

    await executeRequest({
      action: () => requests.editRestrictedDriverFreight(identifier, newValues),
      msgSuccess: 'Viagem editada com sucesso',
      msgError: 'Erro ao editar a viagem, tente novamente.',
      setIsLoadingEdit,
      triggerRefresh,
      callback: () => drawerOnClose(),
    });
  };

  useEffect(() => {
    getEntity();
    getCurrentDriverPosition();
    fetchPercentageCompletion(identifier);
  }, [action, identifier]);

  useEffect(() => {
    if (entity && entity.identifier) {
      getEvents();
      getCheckpoints();

      if (profileRole === 'ROLE_SYSTEM_ADMIN') {
        getEventsFull();
      }
    }
  }, [entity]);

  // Calcular alturas
  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.childElementCount);
    }
    if (travelRef.current) {
      setTravelHeight(travelRef.current.offsetHeight);
    }
    if (mainRef.current) {
      setHeight(mainRef.current.offsetHeight);
    }
  }, []);

  // Atualizar dynamicGrid após os cálculos de altura
  const dynamicGrid = {
    gridTemplateRows: !!entity?.status ? `auto auto minmax(0, 1fr)` : `auto minmax(0, 1fr)`,
    // height: 'calc(-175px + 100vh)',
    // overflow: 'hidden',
  };

  const dynamicTabs = {
    // display: height ? 'grid' : 'initial',
  };

  return (
    <Layout>
      <Menubar
        linkTree={[
          { link: '#', title: 'Transporte' },
          { link: state?.historyBack ?? '/logistic/trip', title: 'Viagens' },
        ]}
        title={`${entity && entity.code ? '#' + entity.code : 'Carregando...'}`}
        detailsTitle={`${entity && entity.code ? '#' + entity.code : 'Carregando...'}`}
        hideAllFilters={true}
        isRefreshLoading={isLoadingEntity}
        triggerRefreshData={() => getEntity()}
        showRefreshData={true}
        refreshSeconds={0}
      />
      {viewport > '1300' ? (
        <Grid
          minH="calc(100vh - 175px)"
          overflow="hidden"
          mx="10px"
          gap="10px"
          templateColumns="700px 1fr"
          className="up-anim"
          ref={mainRef}>
          <Grid direction="column" gap="10px" ref={containerRef} overflow="auto" {...dynamicGrid} h="calc(100vh - 180px)" pr="10px">
            <Card p="20px" display={'grid'} h={{ base: 'min(calc(100vh - 180px), 400px)', md: 'min(calc(100vh - 180px), 157px)' }}>
              <Grid templateColumns="2fr 1fr" alignItems="center" gap="10px">
                <Flex direction="column" justify="space-between" gap="5px">
                  <Flex align="center" gap="20px">
                    {entity?.userDriverName && entity?.userDriverPicture ? (
                      <Image
                        src={`${process.env.REACT_APP_API_URL}${entity?.userDriverPicture?.replace('//profile', '/profile')}`}
                        alt="Foto de container do motorista"
                        w="80px"
                        h="80px"
                        objectFit="cover"
                      />
                    ) : (
                      <Image src={NoPicture} alt="Sem foto de motorista" w="80px" h="80px" />
                    )}

                    <Flex w="full" direction="column">
                      <Text fontSize="1.5rem" fontWeight="bold" color="primary" overflow="ellips">
                        {isLoadingEntity ? (
                          '...'
                        ) : (
                          <>
                            <Tooltip label={entity?.userDriverName}>
                              {entity?.userDriverName ? capitalizeWords(entity.userDriverName) : 'Nome não informado'}
                            </Tooltip>
                          </>
                        )}
                      </Text>

                      <Flex gap="4px" align="center" textColor="primary">
                        {entity?.userDriverTrips} {entity?.userDriverTrips === 1 ? 'Viagem realizada' : 'Viagens realizadas'}
                      </Flex>
                    </Flex>
                  </Flex>

                  {entity && (
                    <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
                      <Flex
                        w="max-content"
                        maxH="30px"
                        p="7px 11px"
                        border="1px"
                        borderColor="#2ECC71"
                        color="#2ECC71"
                        direction="row"
                        align="center"
                        borderRadius="10px"
                        gap="5px">
                        Pegada CO₂: {calcCarbonFormatted}t
                        {entity?.carbonTrack?.retired > 0 ? (
                          <Image src={processCheck} opacity={'1'} />
                        ) : (
                          <Image src={processUncheck} opacity={'1'} />
                        )}
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>

                {entity && entity.userDriverName && (
                  <Flex justify="space-between" direction="column">
                    <Flex direction="column">
                      <Text fontSize="10px">Id Viagem</Text>

                      <Text fontSize="19px" fontWeight="bold">
                        #{entity?.code}
                      </Text>
                    </Flex>

                    <Flex direction="column">
                      <Text fontSize="10px">Veículo</Text>

                      <Text fontSize="19px" fontWeight="bold">
                        {plates?.length > 16 ? <Tooltip label={plates}>{limitChars(plates, 16)}</Tooltip> : limitChars(plates, 16)}
                      </Text>
                    </Flex>

                    <Flex direction="column">
                      <Text fontSize="10px">Transportadora</Text>

                      <Text fontSize="19px" fontWeight="bold">
                        {isLoadingEntity ? '...' : <>{entity?.shippingCompanyName}</>}
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Grid>
            </Card>

            <InformationManagement
              entity={entity}
              additionalInformation={additionalInformation}
              load={load}
              onSubmit={onSubmit}
              percentage={percentage}
              tripPercentage={tripPercentage}
              onOpenEdit={onOpenEdit}
              drawerOnOpen={drawerOnOpen}
              handleClickChangeStatus={handleClickChangeStatus}
            />

            <Tabs position="relative" isFitted variant="unstyled" /*overflow="hidden"  {...dynamicTabs}*/>
              <Card
                header={
                  <Grid position="relative" w="full">
                    <TabList w="full">
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Informações
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Trechos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Rastreio
                        </Text>
                      </Tab>
                      {eventsFull && eventsFull?.length > 0 && (
                        <Tab px="0px" py="10px">
                          <Text fontSize="14px" color="#6C48C2">
                            Eventos
                          </Text>
                        </Tab>
                      )}
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Documentos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Comentários
                        </Text>
                      </Tab>
                    </TabList>

                    <TabIndicator mt="-1.5px" bottom={0} height="2px" bg="#6C48C2" borderRadius="1px" />
                  </Grid>
                }>
                <Grid overflowY="auto">
                  <TabPanels p="15px" pt="0">
                    <InformationTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    <TrajectoryTab entity={entity} setCenter={setCenter} setZoom={setZoom} getEntity={getEntity} />
                    <TrackingTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    {eventsFull && eventsFull.length > 0 && <EventsTab events={eventsFull} setCenter={setCenter} setZoom={setZoom} />}
                    <DocumentTab files={entity?.files} entity={entity} getEntity={getEntity} />
                    <CommentTab entity={entity} />
                  </TabPanels>
                </Grid>
              </Card>
            </Tabs>
          </Grid>

          {!isLoadingEntity && entity && (
            <MapTrajectory
              viewport={viewport}
              w="full"
              h="calc(100vh - 175px)"
              currentDriverPosition={currentDriverPosition}
              identifier={identifier}
              entity={entity}
              isLoadingEntity={isLoadingEntity}
              events={events}
              isLoadingEvents={isLoadingEvents}
              checkpoints={checkpoints}
              additional={additionalInformation}
              center={center}
              setCenter={setCenter}
              zoom={zoom}
              setZoom={setZoom}
            />
          )}
        </Grid>
      ) : (
        <Flex h="1190px" flexDirection="column" overflow="auto" mx="10px" gap="10px" className="up-anim">
          {!isLoadingEntity && entity && (
            <MapTrajectory
              viewport={viewport}
              w="full"
              h="500px"
              currentDriverPosition={currentDriverPosition}
              identifier={identifier}
              entity={entity}
              isLoadingEntity={isLoadingEntity}
              events={events}
              isLoadingEvents={isLoadingEvents}
              checkpoints={checkpoints}
              additional={additionalInformation}
              center={center}
              setCenter={setCenter}
              zoom={zoom}
              setZoom={setZoom}
            />
          )}

          <Flex gap="10px" direction="column">
            <Flex w="full" gap="10px" direction={viewport > '800' ? 'row' : 'column'}>
              <Card w="full" p="20px">
                <Flex w="full" h="full" direction="column" justify="space-between" gap="10px">
                  <Flex align="center" gap="20px">
                    {entity?.userDriverName && entity?.userDriverPicture ? (
                      <Image
                        src={`${process.env.REACT_APP_API_URL}${entity?.userDriverPicture?.replace('//profile', '/profile')}`}
                        alt="Foto de container do motorista"
                        w="60px"
                        h="60px"
                        objectFit="cover"
                      />
                    ) : (
                      <Image src={NoPicture} alt="Sem foto de motorista" w="80px" h="80px" />
                    )}

                    <Flex w="full" direction="column">
                      <Text fontSize="14px" fontWeight="bold" color="primary" overflow="ellips">
                        {isLoadingEntity ? (
                          '...'
                        ) : (
                          <>
                            <Tooltip label={entity?.userDriverName}>
                              {entity?.userDriverName ? capitalizeWords(entity.userDriverName) : 'Nome não informado'}
                            </Tooltip>
                          </>
                        )}
                      </Text>

                      <Flex gap="4px" align="center" textColor="primary" fontSize="11px">
                        {entity?.userDriverTrips} {entity?.userDriverTrips === 1 ? 'Viagem realizada' : 'Viagens realizadas'}
                      </Flex>
                    </Flex>
                  </Flex>

                  {entity && entity.userDriverName && (
                    <>
                      <Flex gap="20px">
                        <Flex direction="column">
                          <Text fontSize="11px">Id Viagem</Text>

                          <Text fontSize="14px" fontWeight="bold">
                            #{entity?.code}
                          </Text>
                        </Flex>

                        <Flex direction="column">
                          <Text fontSize="11px">Veículo</Text>

                          <Text fontSize="14px" fontWeight="bold">
                            {plates?.length > 16 ? <Tooltip label={plates}>{limitChars(plates, 16)}</Tooltip> : limitChars(plates, 16)}
                          </Text>
                        </Flex>

                        <Flex direction="column">
                          <Text fontSize="11px">Transportadora</Text>

                          <Text fontSize="14px" fontWeight="bold">
                            {isLoadingEntity ? '...' : <>{entity?.shippingCompanyName}</>}
                          </Text>
                        </Flex>
                      </Flex>
                    </>
                  )}

                  {entity && (
                    <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
                      <Flex
                        w="max-content"
                        maxH="30px"
                        p="7px 11px"
                        border="1px"
                        borderColor="#2ECC71"
                        color="#2ECC71"
                        direction="row"
                        align="center"
                        borderRadius="10px"
                        gap="5px">
                        Pegada CO₂: {calcCarbonFormatted}t
                        {entity?.carbonTrack?.retired > 0 ? (
                          <Image src={processCheck} opacity={'1'} />
                        ) : (
                          <Image src={processUncheck} opacity={'1'} />
                        )}
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
              </Card>

              <InformationManagement
                entity={entity}
                additionalInformation={additionalInformation}
                load={load}
                onSubmit={onSubmit}
                percentage={percentage}
                tripPercentage={tripPercentage}
                onOpenEdit={onOpenEdit}
                drawerOnOpen={drawerOnOpen}
                handleClickChangeStatus={handleClickChangeStatus}
              />
            </Flex>

            <Tabs position="relative" variant="unstyled" overflow="hidden" isFitted {...dynamicTabs}>
              <Card
                header={
                  <Flex position="relative" w="full">
                    <TabList w="full" gap="5px" overflow="auto">
                      <Tab px="0px" py="10px">
                        <Text fontSize="10px" color="#6C48C2">
                          Informações
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="10px" color="#6C48C2">
                          Trechos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="10px" color="#6C48C2">
                          Rastreio
                        </Text>
                      </Tab>
                      {eventsFull && eventsFull?.length > 0 && (
                        <Tab px="0px" py="10px">
                          <Text fontSize="10px" color="#6C48C2">
                            Eventos
                          </Text>
                        </Tab>
                      )}
                      <Tab px="0px" py="10px">
                        <Text fontSize="10px" color="#6C48C2">
                          Documentos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="10px" color="#6C48C2">
                          Comentários
                        </Text>
                      </Tab>
                    </TabList>

                    <TabIndicator mt="-1.5px" bottom={0} height="2px" bg="#6C48C2" borderRadius="1px" />
                  </Flex>
                }>
                <Flex maxH="450px" overflowY="auto">
                  <TabPanels p="15px" pt="0">
                    <InformationTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    <TrajectoryTab entity={entity} setCenter={setCenter} setZoom={setZoom} getEntity={getEntity} />
                    <TrackingTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    {eventsFull && eventsFull.length > 0 && <EventsTab events={eventsFull} setCenter={setCenter} setZoom={setZoom} />}
                    <DocumentTab files={entity?.files} entity={entity} getEntity={getEntity} />
                    <CommentTab entity={entity} />
                  </TabPanels>
                </Flex>
              </Card>
            </Tabs>
          </Flex>
        </Flex>
      )}

      <DefaultModal
        label="Reprovar"
        title={'Certeza que deseja reprovar?'}
        enabled={false}
        subtitle={
          <>
            Você tem certeza que deseja reprovar esses dados?
            <br /> Ao reprovar o motorista receberá uma notificação com a sua mensagem de observação.
          </>
        }
        buttonColor="red"
        txtButton="Enviar reprovação"
        callback={(decision) => {
          // if (decision) {
          //   changeStatus(identifier, 'reject', { section: section, observation: observation });
          // }
        }}
      />

      <Flex className="float-button-modal">
        <ModalFreight
          entity={entity}
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          title="Edição de viagem"
          subTitle="Você pode salvar as alterações da sua viagem."
          action={action}
          setAction={setAction}
        />
      </Flex>

      {/* drawer */}
      <CommonDrawer heading="Editar viagem" placement="left" isOpen={drawerIsOpen} onOpen={drawerOnOpen} onClose={drawerOnClose}>
        <DynamicForm config={{ initialValues, validationSchema, callback: onSubmitForm }}>
          <DynamicFormTripEdit />

          <DynamicFormFooter>
            <DynamicFormButton name={'Salvar'} type="button" />
          </DynamicFormFooter>
        </DynamicForm>
      </CommonDrawer>

      {(permissions.isSystemAdmin || permissions.logisticTripFullManagement || permissions.logisticTripCreateShareLink) &&
        !hiddenStatuses.includes(entity?.status) && (
          <FloatActionButton
            saveButton={false}
            options={[
              <>
                <Button
                  variant={'floatingGreenButton'}
                  color="white"
                  rightIcon={<MdOutlineShare size={20} />}
                  onClick={() => {
                    onOpenModalLink();
                  }}>
                  Compartilhar viagem
                </Button>
              </>,
            ]}
            isOpen={isOpenFloatButton}
            setIsOpen={setIsOpenFloatButton}
          />
        )}

      <LinkListDrawer isOpen={isOpenModalLink} onClose={onCloseModalLink} identifier={identifier} entity={entity} />
    </Layout>
  );
};

export default TravelDetailPage;
