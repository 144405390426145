import React from 'react';

import moment from 'moment';

import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';

import progressBallGreen from '../../../../assets/images/progressBallGreen.svg';
import progressBallGrey from '../../../../assets/images/progressBallGrey.svg';
import progressBallPurple from '../../../../assets/images/progressBallPurple.svg';

import { getIconByText } from './getIconByText';

const TransportScheduleCircuit = ({ events, loadEventDetails }) => {
  const happenedAt = (event) => {
    const stagePriority = ['Agendado'];

    const priorityStage = stagePriority
      .map((stageName) => event.stage?.find((stage) => stage.stage === stageName))
      .find((stage) => stage);

    const start = priorityStage ? priorityStage.happenedAt.replace(' ', 'T') : event.expectedAt?.replace(' ', 'T') || null;

    return moment(start).format('HH:mm') === '00:00' ? moment(start).format('DD/MM/YYYY') : moment(start).format('DD/MM/YYYY HH:mm');
  };

  const ProcessInfoTag = ({ children }) => {
    return (
      <Flex justify="center" align="center">
        <Text px="10px" color="#6C48C2" fontSize={{ base: '12px', md: '14px' }} border="solid 1px" borderRadius="7px">
          {children}
        </Text>
      </Flex>
    );
  };

  const DetailStep = ({ title, desc, delay, color, hasProgress = true }) => {
    const getBall = () => {
      switch (color) {
        case 'green':
          return progressBallGreen;
        case 'grey':
          return progressBallGrey;
        default:
          return progressBallPurple;
      }
    };

    const getColor = () => {
      switch (color) {
        case 'green':
        case 'grey':
          return 'linear-gradient(90deg, #e6646500 50%, #0F0A1D1C 51% )';
        default:
          return '#6C48C2';
      }
    };

    return (
      <Flex
        w="full"
        textStyle="paragraph"
        textColor={color === 'grey' ? '#0F0A1D54' : '#0F0A1D'}
        direction={{ base: 'row', md: 'column' }}
        gap="5px"
        whiteSpace="pre-wrap"
        style={{ '--delay': delay }}
        className="timeline-step">
        <Flex align="center" minW="100px" w="full" flexDirection={{ base: 'column', md: 'row' }}>
          <Image src={getBall()} />

          {hasProgress && <Box h={{ base: '70px', md: '4px' }} bg={getColor()} bgSize="16%" w={{ base: '4px', md: 'full' }} />}
        </Flex>

        <Flex gap="2px" direction="column" maxW="90px" minWidth="120px" pt={{ base: '5px', md: '0' }}>
          <Text fontWeight="medium">{title}</Text>

          <Text> {moment(desc).format('DD/MM/YYYY HH:mm')}</Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex direction="column" gap="20px" m="20px">
      {events.length > 0 ? (
        events.map((entity, key) => (
          <Flex w="full" h="190px" bgColor="#F9F9F9" borderRadius="6px 0px 0px 6px" key={key}>
            <Box w="10px" bgColor="#7070701A" borderRadius="6px 0px 0px 6px" />

            <Flex w="full" h="full" align="center" px="40px" py="20px" border="1px solid #7070701A">
              <Flex w="full" h="full" direction="column" justify="center" gap="15px">
                <Flex gap="10px">
                  <Tooltip label={entity.modality}>
                    <Image w="30px" src={getIconByText(entity.modality)} />
                  </Tooltip>

                  <Text
                    fontSize="20px"
                    fontWeight="bold"
                    textColor="primary"
                    cursor="pointer"
                    _hover={{ opacity: '0.8' }}
                    onClick={() => loadEventDetails(entity.identifier)}>
                    #{entity.code}
                  </Text>

                  <ProcessInfoTag>{happenedAt(entity)}</ProcessInfoTag>

                  <ProcessInfoTag>Doca: {entity.dockNumber}</ProcessInfoTag>

                  <ProcessInfoTag>{entity.modality}</ProcessInfoTag>
                </Flex>

                <Flex
                  w="full"
                  direction={{ base: 'column', md: 'row' }}
                  overflow={{ base: 'none', md: 'auto' }}
                  py={{ base: '25px', md: '0' }}
                  px={{ base: '35px', md: '0' }}>
                  {entity?.stage?.map((step, key) => {
                    {
                      let isLast = entity.stage.length !== key + 1;
                      let color = isLast ? '' : 'green';

                      return (
                        <DetailStep
                          key={key}
                          title={step.stage}
                          desc={step.happenedAt}
                          hasProgress={isLast}
                          color={color}
                          delay={`0.${key}s`}
                        />
                      );
                    }
                  })}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ))
      ) : (
        <Flex fontWeight="medium" color="#4A5568" justify="center">
          Não há resultados que correspondam aos filtros selecionados.
        </Flex>
      )}
    </Flex>
  );
};

export default TransportScheduleCircuit;
