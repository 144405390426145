import React, { useState } from 'react';

import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, IconButton, Image, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import permissions from '../../../../services/permissions';
import { processCheck, processUncheck } from '../../../../utils/exports';
import { limitChars } from '../../../../utils/strings';

const TableTransportMetrics = ({ entity, profileRole }) => {
  const hasPermissionLogistic = permissions.logistic;

  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  // Função para verificar e formatar valores numéricos
  const formatNumber = (value, isCurrency = false, isPercentage = false) => {
    if (value === undefined || value === null || isNaN(value)) return '-';

    const numberValue = parseFloat(value);
    if (isPercentage) {
      return (
        numberValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + '%'
      );
    }
    return isCurrency
      ? numberValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      : numberValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
  };

  // Função para definir a cor de fundo com base no status
  const getBackgroundColor = (status) => {
    return status === 'RETIRED' ? '#2ECC71 !important' : undefined;
  };

  return (
    <Flex h="500px" direction="column" overflow="auto">
      <Table minW="1600px" w="full">
        <Thead>
          <Tr className="border">
            <Th>
              <Box p="10px !important"></Box>
            </Th>

            <Th>
              <Box p="10px !important">Código</Box>
            </Th>

            {hasPermissionLogistic && (
              <Th>
                <Box p="10px !important"></Box>
              </Th>
            )}

            <Th>
              <Box p="10px !important">Compensar carbono</Box>
            </Th>

            <Th>
              <Box p="10px !important">Peso (kg)</Box>
            </Th>

            <Th>
              <Box p="10px !important">Distância (m)</Box>
            </Th>

            <Th>
              <Box p="10px !important">CO2 (TCO2EQ)</Box>
            </Th>

            <Th>
              <Box p="10px !important">CUSTO CO₂</Box>
            </Th>

            {profileRole === 'ROLE_SYSTEM_ADMIN' && (
              <Th>
                <Box p="10px !important">Descrição</Box>
              </Th>
            )}
          </Tr>
        </Thead>

        <Tbody>
          {entity.length > 0 ? (
            entity.map((item, key) => (
              <Tr className="border" key={key}>
                <Td>
                  <Flex borderColor={getBackgroundColor(item.cts_status)}></Flex>
                </Td>

                <Td>
                  <Flex borderColor={getBackgroundColor(item.cts_status)}>{item?.trip_code ?? '-'}</Flex>
                </Td>

                {hasPermissionLogistic && (
                  <Td>
                    <Flex borderColor={getBackgroundColor(item.cts_status)}>
                      <Tooltip label="Visualizar viagem">
                        <Link to={`/logistic/trip/detail/${item?.trip_identifier}`} target="_blank">
                          <IconButton size="sm" icon={<MdOpenInNew size={20} cursor="pointer" color="#422C76" />} />
                        </Link>
                      </Tooltip>
                    </Flex>
                  </Td>
                )}

                <Td>
                  <Flex minW="180px" w='full' borderColor={getBackgroundColor(item.cts_status)}>
                    {item.cts_status === 'RETIRED' ? (
                      <Flex gap="5px">
                        <Image src={processCheck} opacity={'1'} />
                        Compensado
                      </Flex>
                    ) : (
                      <Flex gap="5px">
                        <Image src={processUncheck} opacity={'1'} />
                        Não compensado
                      </Flex>
                    )}
                  </Flex>
                </Td>

                <Td>
                  <Flex borderColor={getBackgroundColor(item.cts_status)}>{formatNumber(item?.cts_weight_in_kg)}</Flex>
                </Td>

                <Td>
                  <Flex borderColor={getBackgroundColor(item.cts_status)}>{formatNumber(item?.cts_distance_in_meters)}</Flex>
                </Td>

                <Td>
                  <Flex borderColor={getBackgroundColor(item.cts_status)}>{formatNumber(item?.cts_tco2eq)}</Flex>
                </Td>

                <Td>
                  <Flex borderColor={getBackgroundColor(item.cts_status)}>{formatNumber(item?.cts_tco2eq_cost_in_brl, true)}</Flex>
                </Td>

                {profileRole === 'ROLE_SYSTEM_ADMIN' && (
                  <Td>
                    <Flex borderColor={getBackgroundColor(item.cts_status)}>
                      <Tooltip label={item?.cts_description}>
                        {item?.cts_description ? limitChars(item?.cts_description, 100) : '-'}
                      </Tooltip>
                    </Flex>
                  </Td>
                )}
              </Tr>
            ))
          ) : (
            <Tr className="border">
              <Td>
                <Flex></Flex>
              </Td>

              <Td colSpan={25}>
                <Flex justify="center">Não há resultados que correspondam aos filtros selecionados.</Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/* <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} /> */}
    </Flex>
  );
};

export default TableTransportMetrics;
