import { Text } from '@chakra-ui/react';

import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
import formatDocument from '../../../../utils/fields/masks/formatDocument';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  title: 'Análise de Crédito',
  // Estrutura da tabela
  categories: [
    {
      title: 'CNPJ',
      value: 'documentNumber',
      type: 'label',
      align: 'center',
      renderHTML: (item) => <Text>{formatDocument(item.documentNumber)}</Text>,
      /* formatted: (value) => formatDocument(value), */
    },
    {
      title: 'Nome Fantasia',
      value: 'businessName',
    },
    {
      title: 'Razão Social',
      value: 'companyName',
    },
    {
      title: 'Tipo de consulta',
      value: 'modalityText',
      align: 'center',
      type: 'label',
    },
    {
      title: 'Status',
      value: 'status',
      align: 'center',
      variantTag: 'softDashed',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  mainCategoryMobile: 'documentNumber',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 50,
  hasDetailsButton: true,
  detailsRedirectEndpoint: '/vendemmia/credit-analysis/detail/${identifier}',
  tags: ['status'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#2ECC71', label: 'Liberado', value: 'CLEARED' },
    { color: '#d3ab0d', label: 'Atenção', value: 'ATTENTION' },
    { color: '#85828C', label: 'Consultando', value: 'PENDING' },
    { color: '#E74C3C', label: 'Rejeitado', value: 'REJECTED' },
  ],
  // Estilo
  resizeRows: '220px 1fr 1fr 160px 180px 80px',
};
