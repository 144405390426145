export const getColorByText = (modality) => {
  switch (modality) {
    case 'RECEBIMENTO':
      return '#a885f9';
    case 'COLETA':
      return '#6c48c2';
    default:
      return null;
  }
};
