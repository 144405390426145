import React from 'react';

import '../styles/transportScheduleMonth.css';

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { MdTurnedIn } from 'react-icons/md';

import { Box, Flex, Grid, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';

import { formatEvents } from '../utils/formatEvents';
import { getColorByText } from '../utils/getColorByText';
import { getStatusColor } from '../utils/getStatusColor';

const TransportScheduleMonth = ({ calendarRef, events, loadEventDetails }) => {
  const TransportEvent = ({ eventInfo }) => {
    return (
      <Flex
        h="50px"
        w="100% !important"
        maxW="195px"
        bgColor="#F5F5F5"
        align="center"
        gap="3px"
        cursor="pointer"
        borderRadius="0px 6px 6px 0px "
        _hover={{ bgColor: '#F2E9FF' }}
        onClick={() => loadEventDetails(eventInfo.identifier)}>
        <Box h="full" minW="7px" bgColor={getColorByText(eventInfo.modality)} borderRadius="6px 0px 0px 6px" />

        <Flex h="full" w="full" justify="space-between" gap="3px" overflow={'hidden'}>
          <Grid direction="column" overflow={'hidden'} alignItems="center" justifyItems={'start'}>
            <Tooltip label={eventInfo?.companyName}>
              <Text fontSize="10px" fontWeight="medium" textColor="primary" overflow={'hidden'} isTruncated>
                {eventInfo?.companyName}
              </Text>
            </Tooltip>

            <Tooltip label={`Doca ${eventInfo.dockNumber}`}>
              <Text fontSize="9px" textColor="#A6A6A6">
                Doca {eventInfo.dockNumber}
              </Text>
            </Tooltip>
          </Grid>

          <Flex justify="flex-end">
            <Tooltip label={eventInfo.stageModalityFormatted}>
              <Box>
                <MdTurnedIn size="20px" color={getStatusColor(eventInfo.stageModality)} />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <style>
        {`
         .fc-event {
          display: flex;
          justify-content: center;
          width: 100%;
         }
      `}
      </style>

      <FullCalendar
        ref={calendarRef}
        height="100%"
        plugins={[dayGridPlugin]}
        locale={ptBrLocale}
        events={formatEvents(events)}
        firstDay={0}
        dayMaxEvents={2}
        showNonCurrentDates={false}
        dayHeaderFormat={{ weekday: 'long' }}
        moreLinkText={(num) => `+ ${num}`}
        eventContent={(eventInfo) => <TransportEvent eventInfo={eventInfo.event.extendedProps} />}
        headerToolbar={{
          start: '',
          center: '',
          end: '',
        }}
      />
    </>
  );
};

export default TransportScheduleMonth;
