import React, { useEffect, useRef, useState } from 'react';

import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import CommonPage from '../../../components/CommonPage/CommonPage';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { timeline } from '../../../utils/exports';
import { filterOptionsCurrentTrips } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import { ContentLiveVehicleTracking } from './ContentLiveVehicleTracking';

export const LiveVehicleTrackingPage = () => {
  const hasPermission = permissions.logisticRealtime;

  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);

  // Load
  const load = async (filters, key, page) => {
    const res = await executeRequest({
      action: () => requests.listRealtime(filters, page),
      setIsLoading,
    });

    if (request.current && request.current > key) {
      return;
    }
    request.current = key;

    setList(res.data);
    setMetadata(res.meta);
  };

  // Custom hook for refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsCurrentTrips());
  };

  useEffect(() => {
    load();
    getFilterOptions();
  }, []);

  return (
    <CommonPage
      screen="logistic"
      title="Mapa de Tempo real"
      breadcrumbs={[{ link: '#', title: 'Transporte' }]}
      textFilterPlaceholder="Viagem, Motorista, CT-e ou Placa"
      icon={timeline}
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      metadata={metadata}
      load={load}
      list={list}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      showPeriodFilter={false}
      allowEmptySearchPeriod={false}
      showRefreshData={false}
      refreshSeconds={0}>
      <ContentLiveVehicleTracking list={list} />
    </CommonPage>
  );
};
