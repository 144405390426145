import { useEffect, useState } from 'react';

import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd, MdOpenInNew } from 'react-icons/md';
import * as yup from 'yup';

import { Button, Text, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import { DynamicForm, DynamicFormBody, DynamicFormButton, DynamicFormFooter } from '../../../components/CommonForm';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../components/CommonModal/CommonModal';
import Page from '../../../components/Page';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import closeFloatActionButton from '../../../utils/actions/modal/closeFloatActionButton';
import { executeRequest } from '../../../utils/requests/executeRequest';
import addParametersToURL from '../../../utils/url/addParametersToURL ';
import hasSpecificURLParameters from '../../../utils/url/hasSpecificURLParameter';

import { commonListConfig } from './components/commonListConfig';
import { DynamicFormSupplier } from './components/DynamicFormSupplier';

const validationSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  documentNumber: yup.string().required('Campo obrigatório'),
  address: yup.string().required('Campo obrigatório'),
});

// Constantes para mensagens de sucesso e erro
const MESSAGES = {
  createSuccess: 'O fornecedor foi criado com sucesso',
  createError: 'Um erro ocorreu ao gerar o fornecedor. Por favor, tente novamente em breve.',
  editSuccess: 'O fornecedor foi editado com sucesso',
  editError: 'Um erro ocorreu ao editar o fornecedor. Por favor, tente novamente em breve.',
  deleteSuccess: 'O fornecedor foi excluir com sucesso',
  deleteError: 'Um erro ocorreu ao excluir o fornecedor. Por favor, tente novamente em breve.',
};

export const SuppliersPage = () => {
  // Permissions
  const hasPermission = permissions.vendemmiaSuppliers;

  // Instances
  const [list, setList] = useState([]);
  const [sublist, setSublist] = useState();

  const [initialValues, setInitialValues] = useState(null);
  const [identifier, setIdentifier] = useState();
  const [hasType, setHasType] = useState();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Paginate commonList
  const { action, setAction, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  // Modal
  const { isOpen: drawerEditIsOpen, onOpen: drawerEditOnOpen, onClose: drawerEditOnClose } = useDisclosure();
  const { isOpen: modalDeleteIsOpen, onOpen: modalDeleteOnOpen, onClose: modalDeleteOnClose } = useDisclosure();

  // Load
  const load = (filters, _key, page) => {
    getSuppliersList(filters, page);
  };

  // Custom hook for refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  // Requests
  const getSuppliersList = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchProtestSuppliers(filters, page),
      setIsLoading,
    });

    setList(res.data.data);
    setSublist(res.data.data);
    setMetadata(res.data.meta);
  };

  const createNewProcess = async (data) => {
    const formattedData = {
      ...data,
      documentNumber: data.documentNumber.replace(/\D/g, ''), // Remove caracteres não numéricos
    };

    await executeRequest({
      action: () => requests.addSupplier(formattedData),
      msgSuccess: MESSAGES.createSuccess,
      msgError: MESSAGES.createError,
      setIsLoading,
      triggerRefresh,
      callback: drawerEditOnClose,
    });
  };

  const editProcessLetter = async (identifier, data) => {
    await executeRequest({
      action: () => requests.editSupplierDetails(identifier, data),
      msgSuccess: MESSAGES.editSuccess,
      msgError: MESSAGES.editError,
      setIsLoading,
      triggerRefresh,
      callback: drawerEditOnClose,
    });
  };

  // Actions
  const handleOpenEditModal = async (identifier) => {
    const res = await executeRequest({
      action: () => requests.getSupplierDetails(identifier),
      setIsLoading,
    });
    setIdentifier(identifier);
    // Atualize initialValues e abra o Drawer
    updateInitialValues(res.data);
    addParametersToURL({ type: 'edit' });
  };

  const deleteSupplierLetter = async () => {
    await executeRequest({
      action: () => requests.deleteSupplier(identifier),
      msgSuccess: MESSAGES.deleteSuccess,
      msgError: MESSAGES.deleteError,
      setIsLoading,
      triggerRefresh,
    });
  };

  const updateInitialValues = (suppliers) => {
    // Atualiza os valores iniciais do formulário combinando dados fixos do processo com os fornecedores
    const updatedValues = {
      name: suppliers.name || 'PENDENTE',
      documentNumber: suppliers.documentNumber || '',
      address: suppliers.address || '',
      contacts: suppliers.contacts || [],
    };

    setIsDrawerOpen(false);
    setInitialValues(updatedValues);
    setIsDrawerOpen(true);
  };

  const resetInitialValues = () => {
    const updatedValues = {
      name: '',
      documentNumber: '',
      address: '',
      contacts: [],
    };
    setInitialValues(updatedValues);
    setIsDrawerOpen(true);
  };

  const onSubmitForm = (values) => {
    hasType === 'new' && createNewProcess(values);
    hasType === 'edit' && editProcessLetter(identifier, values);
  };

  const addNewProtest = () => {
    closeFloatActionButton();
    // Fechar o Drawer antes de abrir para novo registro
    if (drawerEditIsOpen) {
      drawerEditOnClose();
      setTimeout(() => {
        resetInitialValues();
        addParametersToURL({ type: 'new' });
      }, 300); // Pequeno atraso para garantir que o Drawer seja fechado completamente
    } else {
      resetInitialValues();
      addParametersToURL({ type: 'new' });
    }
  };

  const handleOpenModalDelete = (identifier) => {
    setIdentifier(identifier);
    modalDeleteOnOpen();
  };

  // Component preset
  const customButtons = [
    {
      main: [
        { label: 'Editar', tooltip: 'Editar fornecedor', icon: <MdOpenInNew size={20} />, action: handleOpenEditModal },
        {
          label: 'Excluir',
          tooltip: 'Excluir fornecedor',
          icon: <FaTrashAlt size={16} color="#E74C3C" />,
          action: handleOpenModalDelete,
          /* isButtonDisabled: (item) => item.status !== 'EM_RASCUNHO', */
        },
      ],
      collapsed: [],
    },
  ];

  // Atualiza o hasType quando o parâmetro de URL muda
  useEffect(() => {
    const urlType = hasSpecificURLParameters(['type']).value;
    setHasType(urlType);
  }, [window.location.href]);

  useEffect(() => {
    if (initialValues) {
      // Só abre o Drawer se initialValues estiver definido
      drawerEditOnOpen();
    }
  }, [initialValues]);

  return (
    <Page
      screen="suppliers"
      title="Fornecedores"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="cnpj, nome ou endereço"
      hasPermission={hasPermission}
      load={load}
      list={list}
      metadata={metadata}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      showFilters={false}
      allowFiltersOverride={true}
      allowEmptySearchPeriod={true}
      showPeriodFilter={false}
      FAB={[
        {
          title: 'Cadastrar consulta tributária',
          text: 'Cadastro de consulta tributária',
          action: (
            <Button
              leftIcon={<MdAdd color="#FFFFFF" size={20} />}
              _hover={{ bgColor: '#70D499' }}
              bg="green"
              p={'1.5rem 1.3rem'}
              borderRadius="50px"
              onClick={addNewProtest}>
              <Text mr="10px" color="white">
                Fornecedor
              </Text>
            </Button>
          ),
          modality: 'custom',
        },
      ]}>
      <CommonList
        list={list}
        rawData={list}
        subList={sublist}
        action={action}
        setAction={setAction}
        metadata={metadata}
        setMetadata={setMetadata}
        customButtons={customButtons}
        isLoading={isLoading}
        {...commonListConfig}
      />

      <CommonDrawer
        placement="left"
        heading={'Fornecedor'}
        isOpen={drawerEditIsOpen && isDrawerOpen}
        onOpen={drawerEditOnOpen}
        onClose={() => {
          drawerEditOnClose();
          setIsDrawerOpen(false); // Resetar o estado para garantir a abertura correta na próxima vez
        }}>
        <DynamicForm config={{ initialValues, validationSchema, callback: onSubmitForm }}>
          <DynamicFormBody>
            <DynamicFormSupplier />
          </DynamicFormBody>
          <DynamicFormFooter>
            <DynamicFormButton name={'Salvar'} type="button" loadingText={'Salvando'} isLoading={isLoading} />
          </DynamicFormFooter>
        </DynamicForm>
      </CommonDrawer>

      {/* cancel */}
      <CommonModal
        heading={'Certeza que deseja excluir?'}
        subtitle={'Deseja mesmo excluir esse registro? Ele sumirá automaticamente da lista.'}
        callback={deleteSupplierLetter}
        isOpen={modalDeleteIsOpen}
        onOpen={modalDeleteOnOpen}
        onClose={modalDeleteOnClose}
        variant={'caution'}
        size={'md'}
        removeRadius={true}
      />
    </Page>
  );
};
