export const formatEvents = (events) => {
  const stagePriority = ['Agendado'];

  return events.map((event) => {
    const priorityStage = stagePriority
      .map((stageName) => event.stage?.find((stage) => stage.stage === stageName))
      .find((stage) => stage);

    const start = priorityStage ? priorityStage.happenedAt.replace(' ', 'T') : event.expectedAt?.replace(' ', 'T') || null;

    const end = priorityStage
      ? new Date(new Date(priorityStage.happenedAt).getTime() + 60 * 60000).toISOString().replace(' ', 'T')
      : event.expectedAt
      ? new Date(new Date(event.expectedAt).getTime() + 60 * 60000).toISOString().replace(' ', 'T')
      : null;

    return {
      identifier: event.identifier,
      companyName: event.companyDepositor?.companyName ?? '-',
      code: event?.code ?? '-',
      dockNumber: event.dockNumber ?? '-',
      vehiclePlates: event.vehiclePlates ?? '-',
      stageModality: event.currentStageModality?.slug ?? '-',
      stageModalityFormatted: event.currentStageModality?.name ?? '-',
      modality: event.modality ?? '-',
      start,
      end,
    };
  });
};
