import React, { useContext, useEffect, useState } from 'react';

import { Flex } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import { CommonPageContext } from '../../../components/CommonPage/contexts/CommonPageContext';
import Map from '../../../components/Map/Map';
import requests from '../../../services/requests';
import { generateTrajectoryPath, reduceCheckpointListByDistance } from '../../../utils/geolocation';
import generateIconEvent from '../../../utils/maps/generateIconEvent';
import { tripColors, tripLabelsString } from '../../../utils/translate';

export const ContentLiveVehicleTracking = ({ list, props }) => {
  const {
    isMobile,
    isLoading, // Loading para tela inteira
    load,
    getFilters,
  } = useContext(CommonPageContext);

  const [isLoadingMapData, setIsLoadingMapData] = useState(false);

  const [tripList, setTripList] = useState([]);

  const [currentTripData, setCurrentTripData] = useState(null); // for map handling
  const [currentTripIsTrackable, setCurrentTripTrackable] = useState(false);
  const [currentTrajectory, setCurrentTrajectory] = useState([]);

  const defaultZoom = 2;
  const defaultCenter = {
    lat: 23.73339640721276,
    lng: 2.9533781737686615,
  };
  const [mapBounds, setMapBounds] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(defaultZoom);
  const [center, setCenter] = useState({
    lat: defaultCenter.lat,
    lng: defaultCenter.lng,
  });

  const loadDirections = async (origin, destination, waypoints = []) => {
    if (typeof google === 'undefined' || !google) {
      return null;
    }

    if (!origin || !destination) {
      return;
    }

    var trajectory = null;

    let originAddress = origin?.address;
    let destinationAddress = destination?.address;

    // Se os endereços não estiverem disponíveis, usando as coordenadas de latitude e longitude
    if (!originAddress) {
      originAddress = `${origin?.latitude},${origin?.longitude}`;
    }
    if (!destinationAddress) {
      destinationAddress = `${destination?.latitude},${destination?.longitude}`;
    }

    try {
      const response = await requests.getTrajectoryTripByAddress(originAddress, destinationAddress, JSON.stringify(waypoints));
      if (response?.status == 204) {
        try {
          await new google.maps.DirectionsService().route(
            {
              origin: new google.maps.LatLng(origin.latitude, origin.longitude),
              destination: new google.maps.LatLng(destination.latitude, destination.longitude),
              waypoints: waypoints?.slice(0, Math.min(waypoints.length, 21))?.map((point) => ({
                location: new google.maps.LatLng(point.latitude, point.longitude),
                stopover: true,
              })),
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                trajectory = result;
                requests.addTrajectoryTripByAddress(
                  originAddress,
                  destinationAddress,
                  JSON.stringify(waypoints),
                  JSON.stringify(result)
                );
              }
            }
          );
        } catch (e) {}
      } else if (response?.status == 200) {
        trajectory = response.data;
      }
    } catch (e) {}

    return trajectory;
  };

  const loadPoints = async () => {
    setIsLoadingMapData(true);

    let count = 0;

    let trips = [];
    for (let i in list) {
      let trip = list[i];
      let checkpoints = [];
      let checkpointsMarkers = [];
      let trajectoryMarkers = [];
      // let trajectoryPath = [];
      let isTrackable = false;
      let status = trip.status ?? trip.timeline.currentStatus;

      if (trip.modality === 'APP_MOTORISTA' || trip.modality === 'FRETE_RASTREAVEL') {
        const response = await requests.showCheckpointsDriverFreight(trip.identifier);
        checkpoints = response?.checkpoints;
        isTrackable = true;
      }

      let checkpointsReduced = [];

      if (checkpoints.length > 0) {
        const distance = checkpoints.length < 1000 ? 1 : 3;
        checkpointsReduced = reduceCheckpointListByDistance(checkpoints, distance);

        for (let k in checkpointsReduced) {
          let item = checkpointsReduced[k];

          checkpointsMarkers.push({
            tripIdentifier: trip.identifier,
            isTrackable: isTrackable,
            lat: item.latitude,
            lng: item.longitude,
            color: tripColors[count],
            content: {
              code: trip.code,
              cte: trip?.cteNumber,
              plates: trip?.plates,
              date: item.dateFormatted,
              driver: trip?.userDriverName,
            },
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 5,
              // fillColor: tripColors[count],
              fillColor: '#333',
              fillOpacity: 0.8,
              strokeWeight: 0.2,
            },
          });
        }
      }

      if (trip.trajectories.length > 0) {
        let countLabel = -1;
        let previous = null;
        let current = null;

        for (let k in trip.trajectories) {
          let item = trip.trajectories[k];

          if (
            item.latitude == '0' ||
            item.longitude == '0' ||
            item.latitude == '' ||
            item.longitude == '' ||
            item.latitude === null ||
            item.longitude === null
          ) {
            continue;
          }

          current = {
            lat: item.latitude,
            lng: item.longitude,
          };

          if (previous && current.lat === previous.lat && current.lng === previous.lng) {
            continue;
          }
          countLabel++;

          trajectoryMarkers.push({
            tripIdentifier: trip.identifier,
            isTrackable: isTrackable,
            lat: item.latitude,
            lng: item.longitude,
            color: tripColors[count],
            label: tripLabelsString[countLabel % tripLabelsString.length],
            header: {
              title: item?.type,
              icon: generateIconEvent(item?.type, '#4B0082'),
              color: '#4B0082',
            },
            content: {
              code: trip.code,
              cte: trip?.cteNumber,
              plates: trip?.plates,
              address: item.address,
            },
            icon: {
              url:
                'data:image/svg+xml;charset=UTF-8,' +
                encodeURIComponent(`
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="639.445" height="818" viewBox="0 0 639.445 818">
                    <defs>
                      <filter id="local_icone" x="0" y="0" width="639.445" height="818" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur"/>
                        <feFlood flood-color="#00acff" flood-opacity="0.349"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                      </filter>
                    </defs>
                    <g id="Grupo_4778" data-name="Grupo 4778" transform="translate(-4871.807 23808)">
                      <g transform="matrix(1, 0, 0, 1, 4871.81, -23808)" filter="url(#local_icone)">
                        <path
                          id="local_icone-2"
                          data-name="local_icone"
                        d="M621.435,310.753a314.6,314.6,0,0,1-61.971,186.131l-.782,1.043c-4.65,5.823-182.089,248.363-204.818,279.305A56.07,56.07,0,0,1,308.8,800h-.261A56.14,56.14,0,0,1,263.427,776.8C214.407,709,67.388,504.359,61.651,495.885A306.7,306.7,0,0,1,.028,306.755C3.635,140.7,136.051,6.243,302.018.158a310.758,310.758,0,0,1,319.417,310.6Z"
                          transform="translate(9.01 6)"
                          fill="${tripColors[count]}"
                          fill-opacity="0.9"
                        />
                      </g>
                    </g>
                  </svg>
                `),
              scaledSize: { width: 40, height: 40 },
            },
          });

          previous = current;
        }
      }

      trips.push({
        identifier: trip.identifier,
        code: trip.code,
        color: tripColors[count],
        trajectories: trip.trajectories,
        // trajectoryPath: trajectoryPath,
        trajectoryMarkers: trajectoryMarkers,
        modality: trip.modality,
        status: status,
        isTrackable: isTrackable,
        checkpoints: checkpoints,
        lastCheckpoint: checkpointsReduced.length > 0 ? checkpointsReduced[checkpointsReduced.length - 1] : null,
        lastCheckpointMarker: checkpointsMarkers.length > 0 ? checkpointsMarkers[checkpointsMarkers.length - 1] : null,
        checkpointsMarkers: checkpointsMarkers,
        checkpointsReduced: checkpointsReduced,
      });

      ++count;
    }
    setTripList(trips);

    setIsLoadingMapData(false);
  };

  const calculateBounds = () => {
    if (!tripList || tripList.length === 0) {
      return;
    }

    let bounds = new google.maps.LatLngBounds();

    for (let i in tripList) {
      for (let k in tripList[i].checkpointsReduced) {
        let latitude = tripList[i].checkpointsReduced[k].latitude;
        let longitude = tripList[i].checkpointsReduced[k].longitude;

        if (!latitude || !longitude) {
          continue;
        }

        bounds.extend(new google.maps.LatLng(latitude, longitude));
      }

      for (let k in tripList[i].trajectories) {
        let latitude = tripList[i].trajectories[k].latitude;
        let longitude = tripList[i].trajectories[k].longitude;

        if (!latitude || !longitude) {
          continue;
        }
        bounds.extend(new google.maps.LatLng(latitude, longitude));
      }
    }

    setMapBounds(bounds);
  };

  const handleCurrentTrip = async (trip) => {
    setIsLoadingMapData(true);
    if (currentTrajectory?.length > 0) {
      setCurrentTrajectory([]);
    }

    let bounds = new google.maps.LatLngBounds();
    setCurrentTripData(trip);
    setCurrentTrajectory(await generateTrajectoryPath(trip?.trajectories, trip?.checkpointsReduced, loadDirections, trip?.status));

    for (let k in trip?.checkpointsReduced) {
      let latitude = trip.checkpointsReduced[k].latitude;
      let longitude = trip.checkpointsReduced[k].longitude;

      if (!latitude || !longitude) {
        continue;
      }

      bounds.extend(new google.maps.LatLng(latitude, longitude));
    }

    for (let k in trip?.trajectories) {
      let latitude = trip?.trajectories[k].latitude;
      let longitude = trip?.trajectories[k].longitude;

      if (!latitude || !longitude) {
        continue;
      }
      bounds.extend(new google.maps.LatLng(latitude, longitude));
    }

    setMapBounds(bounds);
    setIsLoadingMapData(false);
  };

  useEffect(() => {
    if (currentTripData) {
      if (typeof currentTripData.isTrackable === 'undefined' && tripList) {
        for (let i = 0; i < tripList.length; i++) {
          if (tripList[i].identifier === currentTripData.identifier) {
            setCurrentTripTrackable(tripList[i].isTrackable);
            break;
          }
        }
      } else {
        setCurrentTripTrackable(currentTripData.isTrackable);
      }
    } else {
      setCurrentTripTrackable(false);
    }
  }, [currentTripData]);

  useEffect(() => {
    loadPoints();
  }, [list]);

  useEffect(() => {
    calculateBounds();
  }, [tripList]);

  useEffect(() => {
    setInterval(() => {
      load();
    }, 120000);
  }, []);

  return (
    <Card m="10px" h="calc(100vh - 200px)" overflow="auto">
      <Flex direction="column" m="20px" gap="20px">
        <Map
          tripList={tripList}
          mapBounds={mapBounds}
          calculateBounds={calculateBounds}
          center={center}
          setCenter={setCenter}
          currentZoom={currentZoom}
          setCurrentZoom={setCurrentZoom}
          currentTripData={currentTripData}
          setCurrentTripData={setCurrentTripData}
          currentTripIsTrackable={currentTripIsTrackable}
          currentTrajectory={currentTrajectory}
          setCurrentTrajectory={setCurrentTrajectory}
          isLoading={isLoading || isLoadingMapData}
          isMobile={isMobile}
          mapHeightControl={'600px'}
        />
      </Flex>
    </Card>
  );
};
