import React, { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Flex, Grid, Image, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip } from '@chakra-ui/react';

import gestao from '../../../assets/images/gestao-ball.png';
import manual from '../../../assets/images/proprio-ball.png';
import api from '../../../assets/images/vendemmia-ball.png';
import Card from '../../../components/Card/Card';
import LabelWithTextH from '../../../components/Generic/LabelWithTextH';
import MapDashboard from '../../../components/Map/MapDashboard';
import { MiniCard } from '../../../components/MiniCard';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useScreenPage from '../../../hooks/useScreenPage';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { processCalculated, processRetired } from '../../../utils/exports';
import { toPercentage } from '../../../utils/text/format/toPercentage';

import AdditionalDataList from './components/AdditionalDataList';
import ChannelFlag from './components/ChannelFlag';
import { CompanyInformation } from './components/CompanyInformation';
import DocumentList from './components/DocumentList';
import FavoriteProcess from './components/FavoriteProcess';
import InconsistentTable from './components/InconsistentTable';
import { ProcessCarbonTag } from './components/ProcessCarbonTag';
import ProcessDetailActivity from './components/ProcessDetailActivity';
import ProcessDetails from './components/ProcessDetails';
import { ProcessDetailsCard } from './components/ProcessDetailsCard';
import ProductList from './components/ProductList';
import QuoteList from './components/QuoteList';

const ProcessDetailPage = () => {
  let profileRole = localStorage.getItem('profile-role');

  const hasPermission = permissions.comexProcess;
  const hasPermissionInconsistency = permissions.vendemmiaInconsistency;

  const resolution = window.innerWidth;

  const { identifier } = useParams();

  const screenPage = useScreenPage();

  const [isLoading, setIsLoading] = useState(true);
  const [process, setProcess] = useState({});

  const [loadUpdates, setLoadUpdates] = useState(true);
  const [updates, setUpdates] = useState([]);

  const [mapInformation, setMapInformation] = useState(null);

  const [points, setPoints] = useState([]);
  const [lines, setLines] = useState([]);

  const [showAdtionalFields, setShowAdtionalFields] = useState('no');

  const modalityImageSuffix = {
    Aéreo: 'air',
    Marítimo: 'nav',
    Terrestre: 'car',
  };

  const imageSuffix = modalityImageSuffix[process.transportModality];
  const index = Math.max(1, Math.floor(process.progressBar / 20 - 1));

  const getProcess = () => {
    setIsLoading(true);

    requests
      .showProcess(identifier, {
        screenPage,
      })
      .then((data) => {
        setProcess({ ...data, vesselMmsi: getVesselMmsi(data) });

        const coordinates = [];
        for (let i in data?.freights) {
          if (data?.freights[i]?.lastLatitude && data?.freights[i]?.lastLongitude) {
            coordinates.push({
              latitude: data?.freights[i]?.lastLatitude,
              longitude: data?.freights[i]?.lastLongitude,
            });
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  const getUpdates = () => {
    requests
      .getProcessUpdates(identifier, {
        screenPage,
      })
      .then((data) => {
        setUpdates(data);
      })
      .finally(() => setLoadUpdates(false));
  };

  const getMapPoints = () => {
    requests.mapPositions(identifier).then((data) => {
      setPoints(data.points);
      setLines(data.lines);
    });
  };

  const getMapPositions = () => {
    requests.processMapPositions(identifier).then((data) => {
      setMapInformation(data);
    });
  };

  const getIcon = () => {
    switch (process.originDescription) {
      case 'api':
        return api;
      case 'manual':
        return manual;
      case 'gestao':
        return gestao;
      default:
        return api;
    }
  };

  const getVesselMmsi = (process) => {
    let mmsi = [];

    if (process.vessel && process.vessel.mmsi && process.vessel.mmsi != '0') {
      mmsi.push(process.vessel);
    }
    if (process.vesselExtra1 && process.vesselExtra1.mmsi) {
      mmsi.push(process.vesselExtra1);
    }
    if (process.vesselExtra2 && process.vesselExtra2.mmsi) {
      mmsi.push(process.vesselExtra2);
    }

    return mmsi;
  };

  const onLoadAdtionalFieldsData = (fields) => {
    if (fields.length > 0) {
      for (const field of fields) {
        if (field.value) {
          setShowAdtionalFields('all'); //Se tiver campos e valores, mostrar pra todos
          return;
        }
      }
      setShowAdtionalFields('admin'); //Se tiver apenas campos, mostrar para "não-clientes"
      return;
    }
    setShowAdtionalFields('no'); //Se não tiver nada, não mostrar pra ninguém
    return;
  };

  const downloadAllFiles = useCallback(() => {
    let link = document.createElement('a');

    link.href = (requests.getApiUrl() + `/open/process/file/${process.identifier}/all/download`).replace('//open', '/open');
    link.target = '_blank';
    link.download = process.identifier + '.zip';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });

  const isShowAdtionalFields = () => {
    if (showAdtionalFields == 'all' || (profileRole !== 'ROLE_CLIENT' && showAdtionalFields == 'admin')) {
      return true;
    }

    return false;
  };

  const load = () => {
    getProcess();
    getUpdates();
    getMapPoints();
    getMapPositions();
  };

  const calcCO2 =
    (process?.carbonTrackDeliverStage?.calculated ?? 0) +
    (process?.carbonTrackDeliverStage?.calculating ?? 0) +
    (process?.carbonTrackDeliverStage?.retired ?? 0) +
    (process?.carbonTrackTransitStage?.calculated ?? 0) +
    (process?.carbonTrackTransitStage?.calculating ?? 0) +
    (process?.carbonTrackTransitStage?.retired ?? 0);

  // soma do carbono retirado
  const calcRetired = process?.carbonTrackDeliverStage?.retired + process?.carbonTrackTransitStage?.retired;

  // formatação do carbono calculando e calculados
  const calcCO2Formatted = calcCO2.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

  // formatação do carbono retirado
  const calcRetiredFormatted = calcRetired.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

  return (
    <Page
      screen="process"
      title={process.code}
      detailsTitle={process.code}
      breadcrumbs={[
        { link: '#', title: 'Importação' },
        { link: '/comex/process', title: 'Processos' },
      ]}
      hasPermission={hasPermission}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      hideAllFilters={true}
      showRefreshData={true}
      allowEmptySearchPeriod={true}
      allowEmptySearchFilterData={true}
      allowEmptySearchString={true}>
      <Grid gridTemplateColumns="2fr 1fr" gap="15px" m="10px">
        <Flex w="full" direction="column" gap="15px">
          <Card overflow="hidden">
            <ScreenLoader isLoading={isLoading}>
              <Flex justify="flex-start" alignItems="center" p="15px" gap="20px" overflow="hidden" flexWrap={'wrap'}>
                <Image src={getIcon()} borderRadius="100%" w="30px" h="30px" />

                {!isLoading && process && (
                  <>
                    {process.channel && <ChannelFlag color={process.channel} />}

                    <Text textStyle="tableTitle" ml={{ base: '0', md: '-15px' }}>
                      {process.code}
                    </Text>
                    {process.clientInternalCode && (
                      <Text p={'1px 12px'} border={'solid 1px #6C48C2'} borderRadius={'7px'} color={'#6C48C2'} fontSize={'14px'}>
                        {process.clientInternalCode}
                      </Text>
                    )}
                    <Tooltip label={`Etapa atual: ${process.currentStageModality}`}>
                      <Image
                        w="30px"
                        h="30px"
                        src={require(`../../../assets/images/process-step-${index}${
                          index > 0 && index < 2 && imageSuffix ? '-' + imageSuffix : ''
                        }${process?.originDescription ? '-mag' : ''}.svg`)}
                        filter={'saturate(1)'}
                        opacity={'1'}
                        transition=".4s"
                        style={{ animationName: 'scale' }}
                      />
                    </Tooltip>
                    <Text textStyle="tableTitle" ml={{ base: '0', md: '-15px' }}>
                      {process.currentStageModality}
                    </Text>
                    <Text>
                      <FavoriteProcess process={process} />
                    </Text>

                    {resolution >= 1600 &&
                      (process.deliveredAtFormatted ? (
                        <>
                          <LabelWithTextH title="Data de entrega" content={process.deliveredAtFormatted} />
                        </>
                      ) : process.billedAtFormatted ? (
                        <>
                          <LabelWithTextH title="Data de faturamento" content={process.billedAtFormatted} />
                        </>
                      ) : process.arrivedAtFormatted ? (
                        <>
                          <LabelWithTextH title="Data de chegada" content={process.arrivedAtFormatted} />
                        </>
                      ) : process.arrivalExpectedAtFormatted ? (
                        <>
                          <LabelWithTextH title="Data esperada de chegada" content={process.arrivalExpectedAtFormatted} />
                        </>
                      ) : process.boardedAtFormatted ? (
                        <>
                          <LabelWithTextH title="Data de embarque" content={process.boardedAtFormatted} />
                        </>
                      ) : process.boardExpectedAtFormatted ? (
                        <>
                          <LabelWithTextH title="Data esperada de embarque" content={process.boardExpectedAtFormatted} />
                        </>
                      ) : (
                        <LabelWithTextH title="Data de abertura" content={process.startedAtFormatted} />
                      ))}

                    <LabelWithTextH title="Responsável" content={process.userResponsibleConexos} />

                    <ProcessCarbonTag process={process} />
                  </>
                )}
              </Flex>

              <MapDashboard
                w="100%"
                h="500px"
                defaultMapModality="origin-to-destination"
                // shouldShowCenterMapButton={false}
                information={mapInformation}
                processDetails={process}
                points={points}
                lines={lines}
                maxZoom={20}
              />
            </ScreenLoader>
          </Card>

          <Tabs position="relative" variant="unstyled" isFitted>
            <Card
              height="100%"
              header={
                <Flex position="relative" w="full">
                  <TabList w="full">
                    <Tab px="0px" py="10px">
                      <Text fontSize="14px" color="#6C48C2">
                        Atividades
                      </Text>
                    </Tab>

                    <Tab px="0px" py="10px">
                      <Text fontSize="14px" color="#6C48C2">
                        Documentos
                      </Text>
                    </Tab>

                    <Tab px="0px" py="10px">
                      <Text fontSize="14px" color="#6C48C2">
                        Produtos
                      </Text>
                    </Tab>

                    <Tab px="0px" py="10px">
                      <Text fontSize="14px" color="#6C48C2">
                        Cotações
                      </Text>
                    </Tab>

                    {isShowAdtionalFields() && (
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Informações complementares
                        </Text>
                      </Tab>
                    )}

                    {hasPermissionInconsistency && (profileRole === 'ROLE_SYSTEM_ADMIN' || profileRole === 'ROLE_OPERATOR') && (
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Inconsistências
                        </Text>
                      </Tab>
                    )}
                  </TabList>

                  <TabIndicator mt="-1.5px" bottom={0} height="2px" bg="#6C48C2" borderRadius="1px" />
                </Flex>
              }>
              <TabPanels overflow={'hidden'}>
                <TabPanel overflow={'auto'}>
                  <ScreenLoader h={isLoading && '100px'} isLoading={isLoading}>
                    {!isLoading && process && (
                      <ProcessDetailActivity updates={updates} process={process} onProcessUpdates={() => getUpdates()} />
                    )}
                  </ScreenLoader>
                </TabPanel>

                <TabPanel overflow={'auto'}>
                  <ScreenLoader h={isLoading && '100px'} isLoading={isLoading}>
                    {!isLoading && process && <DocumentList process={process} downloadAllFiles={downloadAllFiles} />}
                  </ScreenLoader>
                </TabPanel>

                <TabPanel overflow={'auto'}>
                  <ScreenLoader h={isLoading && '100px'} isLoading={isLoading}>
                    {!isLoading && process && <ProductList process={process} />}
                  </ScreenLoader>
                </TabPanel>

                <TabPanel overflow={'auto'}>
                  <ScreenLoader h={isLoading && '100px'} isLoading={isLoading}>
                    {!isLoading && process && <QuoteList process={process} />}
                  </ScreenLoader>
                </TabPanel>

                <TabPanel overflow={'auto'}>
                  <ScreenLoader h={isLoading && '100px'} isLoading={isLoading}>
                    {!isLoading && process && (
                      <AdditionalDataList
                        process={process}
                        onLoadData={onLoadAdtionalFieldsData}
                        updates={updates}
                        onProcessUpdates={() => getUpdates()}
                        getProcess={() => getProcess()}
                      />
                    )}
                  </ScreenLoader>
                </TabPanel>

                <TabPanel overflow={'auto'}>
                  <ScreenLoader h={isLoading && '100px'} isLoading={isLoading}>
                    {!isLoading && process && <InconsistentTable process={process} updates={updates} />}
                  </ScreenLoader>
                </TabPanel>
              </TabPanels>
            </Card>
          </Tabs>
        </Flex>

        <Flex w="full" direction="column" gap="15px" minW="400px">
          <ProcessDetailsCard title={'Informações da empresa'} isLoading={isLoading}>
            {!isLoading && process && (
              <CompanyInformation.Root>
                <CompanyInformation.Info field={'Empresa'} value={process?.client?.companyName} />
                <CompanyInformation.Info field={'CNPJ'} value={process?.client?.documentNumberFormatted} />
              </CompanyInformation.Root>
            )}
          </ProcessDetailsCard>

          <ProcessDetailsCard title={'Linha do tempo'} isLoading={isLoading || loadUpdates}>
            {!isLoading && process && (
              <ProcessDetails waypoints={mapInformation} process={process} updates={updates} loadUpdates={loadUpdates} />
            )}
          </ProcessDetailsCard>

          {process.carbonTrackTransitStage?.status != 'PREVENTED' && (
            <ProcessDetailsCard title={'Carbono total'} isLoading={isLoading}>
              {!isLoading && process && (
                <MiniCard.Root cols={'2'}>
                  <MiniCard.Box>
                    <MiniCard.Header>
                      <Image src={processCalculated} opacity={'1'} w={'8px'} />
                      Pegada de carbono
                    </MiniCard.Header>

                    <MiniCard.Content>
                      <MiniCard.TotalCarbon
                        value={calcCO2Formatted}
                        weight={'toneladas'}
                        tooltip={
                          !process.carbonTrackDeliverStage?.retired || process.carbonTrackTransitStage?.retired
                            ? `O cálculo estimado de sua pegada de carbono para essa etapa foi de ${calcCO2Formatted} toneladas de C02eq com ${toPercentage(
                                (calcRetired / calcCO2) * 100
                              )} compensados`
                            : 'O cálculo para esta etapa está indisponível'
                        }
                      />
                    </MiniCard.Content>
                  </MiniCard.Box>

                  <MiniCard.Box>
                    <MiniCard.Header>
                      <Image src={processRetired} opacity={'1'} w={'14px'} />
                      Carbono neutro
                    </MiniCard.Header>

                    <MiniCard.Content>
                      <MiniCard.TotalCarbon
                        value={calcRetiredFormatted}
                        weight={'toneladas'}
                        tooltip={
                          !process.carbonTrackDeliverStage?.retired || process.carbonTrackTransitStage?.retired
                            ? `O cálculo estimado de sua pegada de carbono para essa etapa foi de ${calcCO2Formatted} toneladas de C02eq com ${toPercentage(
                                (calcRetired / calcCO2) * 100
                              )} compensados`
                            : 'O cálculo para esta etapa está indisponível'
                        }
                      />
                    </MiniCard.Content>
                  </MiniCard.Box>
                </MiniCard.Root>
              )}
            </ProcessDetailsCard>
          )}
        </Flex>
      </Grid>
    </Page>
  );
};

export default ProcessDetailPage;
