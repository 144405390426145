import { useContext } from 'react';

import { CommonListContext } from '../contexts/CommonListContext';

// Importação dinâmica dos filtros
import { PeriodBar } from './Filters/PeriodBar';
// Adicione mais filtros aqui quando necessário
const filterComponents = {
  PeriodBar,
};

export const Controllers = () => {
  // Utiliza o contexto para acessar os filtros personalizados
  const { customFilters } = useContext(CommonListContext);

  return (
    <>
      {customFilters?.map(({ filter, props }, index) => {
        // Obtem o componente baseado no nome do filtro
        const FilterComponent = filterComponents[filter];

        if (!FilterComponent) {
          console.warn(`Filter component "${filter}" não foi encontrado.`);
          return null;
        }

        // Renderiza o filtro com as propriedades passadas
        return <FilterComponent key={index} {...props} />;
      })}
    </>
  );
};
