import React, { useState } from 'react';

import axios from 'axios';
import { ErrorMessage, Field, Formik } from 'formik';
import { useForm } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import {
  Button,
  Flex,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import InputStyled from './InputStyled';
import Form from './styles';

/**
 * @param {string} title - Parâmetro que define o título do Modal.
 * @param {string} subtitle - Parâmetro que define o subtítulo do Modal.
 * @param {string} accept - Parâmetro que define as extensões aceitas para upload.
 * @param {bool} multiple - Parâmetro que define o envio de mais de um arquivo aceitos para upload.
 * @param {string} nameForm - Parâmetro que define a nomenclatura do formulário para envio via api.
 * @param {Function} isOpen - Parâmetro que recebe a função responsável por abrir o modal.
 * @param {Function} onClose - Parâmetro que recebe a função responsável por fechar o modal.
 * @param {Function} requestAction - Parâmetro que recebe função que é disparada ao enviar os anexos e deve retornar uma Promise.
 * @param {Object[]} additional - Parâmetro que cria campos adicionais para relacionar com os anexos.
 * @param {string} additional[].group - Parâmetro para criar o grupo de valor do Formik.
 * @param {string} additional[].name - Parâmetro para definir o nome dos campos.
 * @param {string} additional[].label - Parâmetro para definir o label dos campos.
 * @param {string} additional[].required - Parâmetro para definir se o campo é obrigatório.
 * @param {Object[]} additional[].options - Parâmetro que define o objeto com as opções para seleção.
 * @param {string} additional[].options[].value - Valor da opção.
 * @param {string} additional[].options[].label - Label da opção.
 * @param {bool} needToSendAll - Parâmetro que define o envio sera enviado individualmente ou em conjunto.
 */
const ModalUpload = ({
  title = '',
  subtitle = '',
  type = '',
  accept = '.pdf, .docx, .xml, .jpg, .jpeg, .png, .pfx, csv',
  nameForm = 'file_upload',
  messageConfirm = 'enviado com sucesso!',
  isOpen,
  onClose,
  ownState,
  setOwnState,
  disableAdditionalFields,
  disableMultiUpload,
  multiple = true,
  needToSendAll = false,
  useOwnState = false,
  additional = [],
  requestAction = () => {},
}) => {
  const [progress, setProgress] = useState(); // Estado para armazenar o progresso do upload
  const [textField, setTextField] = useState(''); // Estado adicional de texto (não utilizado diretamente aqui)

  /**
   * Gera os valores iniciais para o formulário com base nos campos adicionais fornecidos.
   */
  const generateInitialValues = (additional) => {
    const initialValues = {
      files: {}, // Objeto para armazenar arquivos a serem enviados
      password: '', // Campo padrão de senha (caso necessário)
    };

    additional?.forEach((element) => {
      initialValues[element.group] = {}; // Cria valores adicionais dinamicamente
    });

    return initialValues;
  };

  /**
   * Remove um arquivo temporário da lista de arquivos carregados.
   */
  const removeTempFile = ({ values, key, setValues }) => {
    const form = document.getElementById('form');
    form.reset(); // Reseta o formulário de arquivos
    clearErrors();
    delete values.files[key]; // Remove o arquivo específico do objeto
    setValues({ ...values }); // Atualiza os valores do formulário
  };

  let source; // Usado para cancelamento do upload via `axios.CancelToken`

  // Hook do React Hook Form para manipulação de erros e submissão de formulário
  const {
    handleSubmit: handleHookFormSubmit,
    register,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  /**
   * Função executada ao enviar o formulário.
   */
  const onSubmit = (values, { setSubmitting }) => {
    // if (useOwnState) setOwnState(true);
    // else setSubmitting(true);

    setSubmitting(true);

    const { files } = values; // Extrai os arquivos do formulário
    const form = document.getElementById('form');
    form.reset();

    // Valida se há arquivos selecionados
    if (!Object.keys(files).length) {
      toast.error('Clique e escolha um arquivo ou arraste.');
    }

    source = axios.CancelToken.source(); // Cria um token para cancelamento
    clearErrors();
    let hasError = false;
    const data = new FormData(); // FormData para envio dos arquivos

    Object.entries(files).forEach(([key, file]) => {
      const docs = new FormData();

      // Adiciona campos adicionais configurados no formulário
      !disableAdditionalFields &&
        additional.forEach((element) => {
          if (element.fieldType === 'text') {
            const textValue = Object.entries(values[element.group])?.length > 0 ? values[element.group] : '';
            docs.append(`${nameForm}[${element.group}]`, textValue);
            data.append(`${nameForm}[${key}][${element.group}]`, textValue);
          } else {
            const optionValue = values[element.group][key];

            // Validação de campos obrigatórios
            if (element.required && (optionValue === undefined || optionValue === '')) {
              hasError = true;
              setError(`${element.group}.${key}`, {
                type: 'manual',
                message: 'Campo Obrigatório',
              });
            } else if (optionValue !== undefined) {
              docs.append(`${nameForm}[${element.group}]`, optionValue);
              data.append(`${nameForm}[${key}][${element.group}]`, optionValue);
            }
          }
        });

      if (hasError) {
        // if (useOwnState) setOwnState(false);
        // else setSubmitting(false);
        setSubmitting(false);
        return;
      }

      if (!hasError) {
        docs.append(`${nameForm}[file]`, file);
        data.append(!disableMultiUpload ? `${nameForm}[${key}][file]` : `${nameForm}[file]`, file);
        const config = {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress({ [key]: percentCompleted });
          },
          cancelToken: source.token,
        };
        if (!needToSendAll) {
          requestAction(docs, config)
            .then(() => {
              delete values.files[key];
              // if (useOwnState) setOwnState(false);
              // else setSubmitting(false);
              setSubmitting(false);
              toast.success(`${file.name} ${messageConfirm}`);
              onClose();
            })
            .catch((e) => {
              // if (useOwnState) setOwnState(false);
              // else setSubmitting(false);
              setSubmitting(false);
              toast.error(`${file.name} falhou, tente novamente.`);
            });

          return;
        }
      }
    });

    // Envio em lote dos arquivos (se `needToSendAll` for verdadeiro)
    if (needToSendAll) {
      requestAction(data, null)
        .then(() => {
          // if (useOwnState) setOwnState(false);
          // else setSubmitting(false);
          setSubmitting(false);
          toast.success(`Arquivos enviados com sucesso`);
          onClose();
        })
        .catch(() => {
          // if (useOwnState) setOwnState(false);
          // else setSubmitting(false);
          setSubmitting(false);
          toast.error(`Upload de arquivos falhou, tente novamente.`);
        });
    }
  };

  /**
   * Reseta o formulário e fecha o modal.
   */
  const handleCloseModal = () => {
    reset();
    onClose();
  };

  /**
   * Hook para resetar o estado quando o modal for fechado.
   */
  // useEffect(() => {
  //   if (useOwnState && ownState === false) {
  //     reset();
  //     onClose();
  //   }
  // }, [useOwnState, ownState]);

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={handleCloseModal} size="4xl" className="custom-modal">
        <style>
          {`
            #form input[type="text"] {
              height: 2.5rem;
              background: transparent;
              padding: 0 16px;
              border: 1px solid #b1b1b1;
              border-radius: 0.375rem;
            }
          `}
        </style>
        <ModalOverlay />
        <ModalContent maxW="none" w="70vw" px="40px" py="30px" overflowY="auto">
          <ModalBody p="0">
            <Flex direction="column" alignItems="flex-start" justifyContent="center" mb="8">
              <Text textColor="#aeacb3" className="up-anim" fontSize={15}>
                {title}
              </Text>
              <Text fontWeight="medium" textColor="#0F0A1D" fontSize={20} className="up-anim">
                {subtitle}
              </Text>
            </Flex>
            <Formik initialValues={generateInitialValues(additional)} onSubmit={onSubmit}>
              {({ getFieldProps, setValues, handleSubmit, isSubmitting, values, setFieldValue, setErrors }) => (
                <Form id="form" onSubmit={handleHookFormSubmit(handleSubmit)}>
                  <Flex direction="column" gap="15px" w="full">
                    <InputStyled
                      name="files"
                      id="files"
                      accept={accept}
                      multiple={multiple}
                      onChange={(e) => {
                        const files = Array.from(e.target?.files);
                        files.forEach((file) => {
                          const key = file.lastModified;
                          setFieldValue(`files[${key}]`, file);
                        });
                      }}
                    />
                    {!Object.keys(values.files).length && (
                      <ErrorMessage component="p" className="text-red mb-4 font-light text-xs" name={`files`} />
                    )}

                    {type === 'operation' && (
                      <Text>
                        Fazer download de planilha atualizada selecionando:
                        <>
                          <strong> {`"Tabela Vigente"`}</strong> e <strong>{`"Tabela XLSX com descrição concatenada"`} </strong>
                        </>
                        <Link
                          color="blue"
                          target="_blank"
                          href="https://portalunico.siscomex.gov.br/classif/#/nomenclatura/tabela?perfil=publico">
                          clicando aqui.
                        </Link>
                      </Text>
                    )}
                  </Flex>

                  <Flex direction="column" gap="15px" w="full" mt="15px">
                    <Flex direction="row" w="full" justifyContent="flex-end">
                      <Button
                        border="1px"
                        px="15px"
                        py="12px"
                        color="#6C48C2"
                        borderColor="#6C48C2"
                        fontSize="14px"
                        bgColor="transparent"
                        onClick={handleCloseModal}>
                        Cancelar
                      </Button>
                      <Button
                        ml="4"
                        variant="primary"
                        px="15px"
                        py="15px"
                        fontSize="14px"
                        loadingText="Carregando..."
                        isLoading={isSubmitting}
                        onClick={() => {
                          handleSubmit();
                        }}
                        isDisabled={Object.keys(values.files).length === 0}>
                        Salvar
                      </Button>
                    </Flex>

                    <Table className="table-default" w="100%">
                      <Thead>
                        <Tr>
                          <Th>Arquivo</Th>

                          {!disableAdditionalFields && additional.map((element, index) => <Th key={index}>{element?.label}</Th>)}

                          <Th>Ações</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Object.entries(values.files)?.length > 0 ? (
                          <>
                            {Object.entries(values.files).map(([key, file]) => (
                              <Tr key={key}>
                                <Td>{file.name}</Td>

                                {!disableAdditionalFields &&
                                  additional.map((element, index) => (
                                    <Td key={index}>
                                      {element.fieldType === 'text' ? (
                                        <Field
                                          type="text"
                                          placeholder="Preencha o valor..."
                                          value={values[`${element.group}[${key}]`]}
                                          name={`${element.name}[${key}]`}
                                          onChange={(item) => {
                                            setFieldValue(`${element.group}`, item?.target?.value);
                                          }}
                                        />
                                      ) : (
                                        <Select
                                          /* style={{ width: '200px' }} */
                                          placeholder="Selecione"
                                          value={values[`${element.group}[${key}]`]}
                                          name={`${element.name}[${key}]`}
                                          {...register(`${element.group}[${key}]`, { required: element.required })}
                                          onChange={(item) => {
                                            setFieldValue(`${element.group}[${key}]`, item?.target?.value);

                                            if (typeof element?.additionalOnChange === 'function') {
                                              element.additionalOnChange(item?.target?.value);
                                            }
                                          }}>
                                          {element.options?.length > 0 &&
                                            element.options.map(function (option, index2) {
                                              return (
                                                <option key={index2} value={option.value}>
                                                  {option.label}
                                                </option>
                                              );
                                            })}
                                        </Select>
                                      )}
                                      {/* Exibir mensagem de erro apenas se o campo for obrigatório e o formulário foi submetido */}
                                      {errors[element.group]?.[key] && (
                                        <p
                                          style={{
                                            fontSize: '12px',
                                            color: '#f22c3d',
                                            marginTop: '2px',
                                            marginLeft: '3px',
                                          }}>
                                          Campo Obrigatório
                                        </p>
                                      )}
                                    </Td>
                                  ))}

                                <Td>
                                  <IconButton
                                    bg="none"
                                    p="0"
                                    icon={<FaTrashAlt size={20} color="#EF8484" />}
                                    onClick={() => removeTempFile({ values, key, setValues })}
                                  />
                                </Td>
                              </Tr>
                            ))}
                          </>
                        ) : (
                          <Tr>
                            <Td colSpan={2 + additional.length}>
                              <Flex justify="center" my="5px">
                                Nenhum arquivo selecionado
                              </Flex>
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </Flex>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalUpload;
