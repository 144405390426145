export const getStatusColor = (status) => {
  const statusColors = {
    CADASTRADO: '#F1C40F',
    AGENDADO: '#3498DB',
    CHEGOU: '#4CAF50',
    NO_PATIO: '#8E44AD',
    NA_DOCA: '#FF9800',
    CONCLUIDO: '#2E7D32',
    CANCELADO: '#F44336',
    RECUSADO: '#B71C1C',
    default: '#422C76',
  };

  return statusColors[status] || statusColors.default;
};
