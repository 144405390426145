import moment from 'moment';

export const formattedDate = (type, period) => {
  switch (type) {
    case 'day':
      return moment(period.start).format('DD/MM/Y');

    case 'month':
      return moment(period.start).format('MMMM/YYYY').toUpperCase();

    default:
      return `${moment(period.start).format('DD/MM/Y')} até ${moment(period.end).format('DD/MM/Y')}`;
  }
};
